import * as XLSX from "xlsx";

export function saveXLSX(jsonData: any, spreadsheetName: string, filename: string) {
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(jsonData, { cellDates: true }), spreadsheetName);
  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export async function readXLSX(file: File): Promise<{ [k: string]: any | null }[] | null> {
  const workbook = XLSX.read(await file.arrayBuffer(), { cellDates: true });

  const sheet = workbook.Sheets[workbook.SheetNames[0]!];
  if (!sheet) {
    console.warn("No sheet found");
    return null;
  }
  const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];
  if (rows.length < 2) {
    console.warn("Sheet has no rows");
    return null;
  }
  const headings = rows[0]!;
  return rows.slice(1).map((row) =>
    Object.fromEntries(
      headings.map((key, i) => {
        let value = row[i] === undefined ? null : row[i];
        return [key, value];
      }),
    ),
  ) as { [k: string]: any | null }[];
}

export function asOptionalString(item: any): string | undefined {
  if (typeof item === "string") return item;
  if (typeof item === "undefined" || item === null) return undefined;
  try {
    return item.toString();
  } catch (_: any) {
    // noop
  }
  return undefined;
}
