import { Feature, FeatureCollection, GeoJsonObject, MultiPolygon, Point, Position } from "geojson";
import L, { DivIcon, Icon, PathOptions, PointExpression } from "leaflet";
import { Map as MaplibreMap } from "maplibre-gl";
import { useEffect, useMemo, useState } from "react";
import { GeoJSON, MapContainer, Marker, useMap, useMapEvent } from "react-leaflet";
import polylabel from "../../../helpers/polylabel";
import { CongSettings } from "../../../types/scheduling/settings";
import { MapPoint, Territory, TerritoryAddress } from "../../../types/scheduling/territory";
import { MapLibreTileLayer } from "./MapLibreTileLayer";
import { MapManager, tileProvider, tileStyle } from "./common";
import GeomanWrapper from "./geoman";

export type LabeledLayer = {
  label: string;
  geojson: GeoJsonObject;
  id: number;
};

type TextAnnotation = {
  latlng: L.LatLng;
  text: string;
};

export function MapWithGeoJson(props: {
  territory?: Territory;
  settings: CongSettings;
  geojson?: GeoJsonObject;
  addresses?: TerritoryAddress[];
  labeledLayers?: LabeledLayer[];
  navigateToMap: (id: number) => void;
  onClick?: (p: Position) => void;
  restrictMarkerToBounds?: boolean;
  fullHeight?: boolean;
  hideZoomControl?: boolean;
  pathOptions?: PathOptions;
  setMaplibreMap: (mlMap: MaplibreMap) => void;
  pubMode?: boolean;
}) {
  const [needCenter, setNeedCenter] = useState(true);
  const [labeledLayers, setLabeledLayers] = useState(props.labeledLayers ?? []);

  useEffect(() => {
    if (!props.labeledLayers) return;
    setLabeledLayers(props.labeledLayers);
  }, [props.labeledLayers]);

  const divStyle = props.fullHeight === true ? { height: "60vh" } : undefined;
  const provider = tileProvider(props.settings);
  const annotations: FeatureCollection | undefined = props.territory?.annotations
    ? JSON.parse(props.territory.annotations)
    : undefined;

  const getLineAnnotations = (): FeatureCollection | undefined => {
    if (!annotations?.features.some((f) => f.geometry.type === "LineString")) return;
    return {
      type: "FeatureCollection",
      features: annotations?.features.filter((f) => f.geometry.type === "LineString"),
    };
  };
  const lineAnnotations = getLineAnnotations();
  const textAnnotations: TextAnnotation[] =
    annotations?.features
      .filter((f): f is Feature<Point> => f.geometry.type === "Point" && !!f.properties?.text)
      .map((f) => ({
        latlng: new L.LatLng(f.geometry.coordinates[1] ?? 0, f.geometry.coordinates[0] ?? 0),
        text: f.properties?.text ?? "",
      })) || [];

  console.log("textAnnotations", textAnnotations);

  return (
    <div id="map" className="leaflet-container mb-3" style={divStyle}>
      <MapContainer scrollWheelZoom={true} zoomControl={!props.hideZoomControl}>
        <MapManager
          territory={props.territory}
          needCenter={needCenter}
          setNeedCenter={setNeedCenter}
          setZoomLevel={() => {}}
          labeledLayers={labeledLayers}
          setLabeledLayers={setLabeledLayers}
          setMaplibreMap={props.setMaplibreMap}
          onClick={props.onClick}
          restrictMarkerToBounds={props.restrictMarkerToBounds}
          addresses={props.addresses ?? []}
          pubMode={props.pubMode}
        />
        {!!props.geojson && <GeoJSON data={props.geojson} pathOptions={props.pathOptions} />}
        {!!lineAnnotations && <GeoJSON data={lineAnnotations} />}
        {textAnnotations.map((ta, i) => (
          <Marker key={i} position={ta.latlng} textMarker text={ta.text} />
        ))}

        {labeledLayers?.map((ll) => (
          <PolygonWithText
            key={ll.id}
            ondblclick={() => props.navigateToMap(ll.id)}
            pathOptions={{ color: "green" }}
            geojson={ll.geojson}
            label={ll.label}
            // labelClass={zoomLevel > 15 ? "leaflet-divicon-label-lg" : "leaflet-divicon-label"}
          />
        ))}

        {/*don't fully understand this, but we need to pull in geoman so text labels work*/}
        {props.pubMode && <GeomanWrapper noEdit setGeojson={() => {}} />}

        {!!props.addresses && <AddressMarkers addresses={props.addresses} />}
        <MapLibreTileLayer attribution={provider.attribution} style={tileStyle(provider)} />
      </MapContainer>
    </div>
  );
}

const canvas = document.createElement("canvas");

type TextWidths = {
  sm: TextMetrics;
  md: TextMetrics;
  lg: TextMetrics;
};

enum SizeClass {
  Small,
  Medium,
  Large,
}

function getTextWidths(text: string): TextWidths | undefined {
  const context = canvas.getContext("2d");
  if (!context) return;
  context.font = "bold 1em sans-serif";
  const sm = context.measureText(text);
  context.font = "bold 1.5em sans-serif";
  const md = context.measureText(text);
  context.font = "bold 2em sans-serif";
  const lg = context.measureText(text);
  return { sm: sm, md: md, lg: lg };
}

export function PolygonWithText(props: {
  ondblclick: () => void;
  geojson: GeoJsonObject;
  label: string;
  pathOptions?: PathOptions;
  // labelClass?: string;
  pmIgnore?: boolean;
  snapIgnore?: boolean;
}) {
  // const textSizeClass = useRef<SizeClass | null>(null);
  const [textSizeClass, setTextSizeClass] = useState<SizeClass>();

  const geo = L.geoJSON(props.geojson);
  const bounds = geo.getBounds();

  const getCenter = (): L.LatLng => {
    if (props.geojson.type === "MultiPolygon") {
      const polygon = props.geojson as MultiPolygon;
      if (polygon.coordinates[0]) {
        const location = polylabel(polygon.coordinates[0], 0.000001).position;
        if (location && location[0] && location[1]) {
          return new L.LatLng(location[1], location[0]);
        }
      }
    }
    return bounds.getCenter();
  };

  const textWidths = useMemo((): TextWidths | undefined => {
    // the spaces are for a little padding
    return getTextWidths(` ${props.label} `);
  }, [props.label]);

  const labelClass = (): string => {
    switch (textSizeClass) {
      case SizeClass.Small:
        return "leaflet-divicon-label";
      case SizeClass.Medium:
        return "leaflet-divicon-label-md";
      case SizeClass.Large:
        return "leaflet-divicon-label-lg";
      default:
        return "";
    }
  };

  const textSize = (): PointExpression => {
    if (!textWidths) return [0, 0];
    const height = (tm: TextMetrics): number => tm.actualBoundingBoxAscent + tm.actualBoundingBoxDescent;
    switch (textSizeClass) {
      case SizeClass.Small:
        return new L.Point(textWidths.sm.width, height(textWidths.sm));
      case SizeClass.Medium:
        return new L.Point(textWidths.md.width, height(textWidths.md));
      case SizeClass.Large:
        return new L.Point(textWidths.lg.width, height(textWidths.lg));
      default:
        return [0, 0];
    }
  };

  const center = getCenter();
  const iconLabelClass = labelClass();
  const text = L.divIcon({
    html: props.label,
    className: iconLabelClass,
    iconSize: textSize(), // if we don't calculate this size, then the left side of the text goes where the center should be
  });

  const MapInspector = () => {
    const map = useMap();

    const calcTextSize = () => {
      try {
        const ne = map.latLngToLayerPoint(bounds.getNorthEast());
        const sw = map.latLngToLayerPoint(bounds.getSouthWest());
        const polyWidth = ne.x - sw.x;
        if (textWidths) {
          if (polyWidth > textWidths.lg.width) setTextSizeClass(SizeClass.Large);
          else if (polyWidth > textWidths.md.width) setTextSizeClass(SizeClass.Medium);
          else if (polyWidth > textWidths.sm.width) setTextSizeClass(SizeClass.Small);
          else setTextSizeClass(undefined);
        }
      } catch (err: any) {
        console.error("calc poly label", err);
      }
    };

    useMapEvent("zoomend", calcTextSize);
    useEffect(calcTextSize, [map]);

    return null;
  };

  return (
    <>
      <MapInspector />
      <GeoJSON
        data={props.geojson}
        pathOptions={props.pathOptions}
        pmIgnore={props.pmIgnore}
        snapIgnore={props.snapIgnore}
        onEachFeature={(_feature, layer) => {
          layer.on("dblclick", () => props.ondblclick());
        }}
      >
        {!!iconLabelClass && <Marker position={center} icon={text} />}
      </GeoJSON>
    </>
  );
}

export function AddressMarkers(props: { addresses: TerritoryAddress[] }) {
  const iconSize: PointExpression = [20, 20];
  // set iconAnchor so the bottom of the pin is on the location
  const iconAnchor: PointExpression = [10, 20];

  const markerIcon = (ta: TerritoryAddress): Icon | DivIcon | undefined => {
    const iconUrl = addressIconUrl(ta);
    return new L.Icon({ iconUrl, iconSize: iconSize, iconAnchor: iconAnchor });
  };

  return (
    <>
      {props.addresses
        ?.filter(
          (
            ta,
          ): ta is TerritoryAddress & {
            location: MapPoint;
          } => !!ta.location && !!ta.location.x && !!ta.location.y && !ta.hideOnMap,
        )
        .map((ta) => (
          <Marker
            key={ta.id}
            position={{ lng: ta.location.x, lat: ta.location.y }}
            icon={markerIcon(ta)}
            snapIgnore={true} // need to use snapIgnore. using pmIgnore breaks saving map changes
          />
        ))}
    </>
  );
}

export function addressIconUrl(ta: TerritoryAddress, excludeLabel = false) {
  if (!ta.location) {
    return "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="; //empty image
  }
  const color = ta.dnc ? "red" : "darkblue";
  const label = ta.dnc ? "X" : (ta.pinLabel ?? "");
  const letter = excludeLabel ? "" : label;
  //pin icon with letter and given color
  return `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' fill='none'%3E%3Cpath fill='${color}' d='M15.23 30c-.205 0-.408-.038-.612-.115a1.835 1.835 0 0 1-.535-.305c-3.72-3.287-6.497-6.338-8.331-9.154C3.917 17.61 3 14.98 3 12.535c0-3.822 1.23-6.866 3.689-9.134C9.148 1.134 11.995 0 15.229 0c3.236 0 6.083 1.134 8.543 3.401 2.459 2.268 3.688 5.312 3.687 9.134 0 2.446-.918 5.077-2.752 7.892-1.834 2.816-4.611 5.867-8.331 9.153a1.83 1.83 0 0 1-.535.305 1.732 1.732 0 0 1-.612.115Z' /%3E%3Ctext x='50%25' y='50%25' fill='white' dominant-baseline='middle' font-family='Arial' font-size='20' text-anchor='middle'%3E${letter}%3C/text%3E%3C/svg%3E`;
}
