import dayjs from "dayjs";
import i18n from "i18next";
import { jsPDF } from "jspdf";
import autoTable, { CellHookData, RowInput, Styles } from "jspdf-autotable";
import { MapKind } from "../../../@adapters/map-export/mapglExport";
import { territoryApi } from "../../../api/territory";
import { addressToString } from "../../../helpers/address";
import {
  dateStringCompare,
  dateToString,
  getDayjs,
  localizedLanguageName,
  serviceYearStart,
  stringToDate,
  stringToLocaleDate,
  stringToLongLocaleDate,
} from "../../../helpers/dateHelpers";
import { PythonI18nInterpolation } from "../../../helpers/globals";
import { t } from "../../../helpers/locale";
import {
  boldFont,
  getHeightForText,
  getWidthForLine,
  halfInch,
  largestFontSize,
  longestLine,
  newPDF,
  newPDFWithHeader,
  normalFont,
  PDFFooterLeft,
  S13Header,
  truncateToWidth,
} from "../../../helpers/pdf";
import { NBSP } from "../../../helpers/util";
import { Cong } from "../../../types/cong";
import { PDFOrientation } from "../../../types/paper";
import { CongSettings } from "../../../types/scheduling/settings";
import {
  Territory,
  TerritoryAddress,
  TerritoryAddressLocationType,
  TerritoryRecord,
} from "../../../types/scheduling/territory";
import User from "../../../types/user";
import { territoryCompare, territoryFilename, terrLabel, tileProvider } from "./common";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";

type ColumnStyles = { [key: string]: Partial<Styles> };
const margin = halfInch;
const bodyFontSize = 8;
const headerFontSize = 8;
const rowsPerPage = 20;
const recordsPerRow = 4;

const smLineThinkness = 0.005;
const mdLineThinkness = 0.01;
const lgLineThinkness = 0.05;

export async function territoryS13(props: {
  territories: Territory[];
  territoryRecords: TerritoryRecord[];
  userMap: Map<number, User>;
  cong: Cong;
  years: number[];
  summary: boolean;
}) {
  const { territories, territoryRecords, userMap, cong, years, summary } = props;
  const allRecords = years.map((year) => {
    const yearStart = dayjs(serviceYearStart(new Date(year, 2, 1)));
    const serviceYearRecords = getRecordsForServiceYear(territories, territoryRecords, yearStart, summary);
    return {
      serviceYear: year,
      residentialRecords: serviceYearRecords.filter((t) => !t.terr.business),
      businessRecords: serviceYearRecords.filter((t) => t.terr.business),
    };
  });

  const pageCount = allRecords.reduce(
    (acc, cur) =>
      acc +
      Math.ceil(cur.residentialRecords.length / rowsPerPage) +
      Math.ceil(cur.businessRecords.length / rowsPerPage),
    0,
  );

  // S-13 is always A4
  const doc = await newPDF({
    title: t("schedules.territory.s13.title"),
    localeCode: cong.locale.code,
    paperSize: "a4",
  });

  let page = 0;
  allRecords.forEach(({ serviceYear, residentialRecords, businessRecords }) => {
    [residentialRecords, businessRecords].forEach((records) => {
      Array(Math.ceil(records.length / rowsPerPage))
        .fill(0)
        .map((_, i) => records.slice(i * rowsPerPage, i * rowsPerPage + rowsPerPage))
        .forEach(async (pageRecords) => {
          page++;
          if (page != 1) doc.addPage();
          const res = !pageRecords[0]?.terr.business;

          const initY = S13Header(
            doc,
            t("schedules.territory.s13.title"),
            t("general.service-year"),
            serviceYear,
            res ? "" : t("schedules.territory.business"),
          );
          doc.setFontSize(8);
          doc.text(`*${t("schedules.territory.s13.footnote")}`, margin, 10.7 + 0.2, {
            maxWidth: doc.internal.pageSize.width - margin * 2,
          });
          doc.text(`S-13-${cong.locale.symbol}`, margin, 10.7 + 0.5);
          const pagesText = `${page}/${pageCount}`;
          const pagesTextWidth = doc.getTextWidth(pagesText);
          doc.text(pagesText, doc.internal.pageSize.width - margin - pagesTextWidth, 10.7 + 0.5);

          const curY = initY + 0.05;
          await territoryRecordSheets(doc, curY, pageRecords, userMap);
        });
    });
  });

  doc.save(`${cong.name}_${t("schedules.territory.territories")}_${years.join("-")}.pdf`);
}

function getRecordsForServiceYear(
  territories: Territory[],
  territoryRecords: TerritoryRecord[],
  yearStart: dayjs.Dayjs,
  summary: boolean,
) {
  const yearEnd = yearStart.add(1, "year");

  return territories.sort(territoryCompare).flatMap((terr) => {
    const terrRecs = territoryRecords
      .filter((tr) => tr.territory_id === terr.id)
      .sort((a, b) => dateStringCompare(a.start!, b.start!));
    // by using !isBefore, we get on-or-after. if we just use isAfter, then it doesn't account for records
    // checked out on September 1, when the service year starts.
    const validRecs = terrRecs.filter(
      (r) => !dayjs(r.start).isBefore(yearStart, "day") && dayjs(r.start).isBefore(yearEnd, "day"),
    );

    const recordChunks = summary
      ? [validRecs.slice(-4)]
      : Array(Math.ceil(validRecs.length / recordsPerRow))
          .fill(0)
          .map((_, i) => validRecs.slice(i * recordsPerRow, i * recordsPerRow + recordsPerRow));

    // lastShownEnd is the earliest end date in recordChunks.
    const lastShownEnd = recordChunks.flat().filter((r) => !!r.end)[0]?.end;
    // lastWorkedBefore is the date that the lastWorked must be before. It represents the earliest end date
    // in recordChunks. If undefined, there were no end dates in recordChunks, so set it to the end of the service year
    const lastWorkedBefore = lastShownEnd ? dayjs(lastShownEnd) : yearEnd;
    // lastWorked is the latest end date of a territory record prior to the ones in recordChunks (if any)
    const lastWorked =
      terrRecs
        .filter((r) => !!r.end) // only records with an end date
        .sort((a, b) => dateStringCompare(b.end!, a.end!)) // reverse sort
        // get the first one before the lastWorkedBefore date
        .find((r) => dayjs(r.end).isBefore(lastWorkedBefore))?.end ?? "";

    if (!recordChunks.length) {
      return [{ terr, lastWorked, records: [] }];
    }

    return recordChunks.map((records) => {
      return {
        terr,
        lastWorked,
        records,
      };
    });
  });
}

async function territoryRecordSheets(
  doc: jsPDF,
  initialY: number,
  pageRecords: {
    terr: Territory;
    lastWorked: string;
    records: TerritoryRecord[];
  }[],
  userMap: Map<number, User>,
): Promise<number> {
  let lastEndY = 0;
  const tableWidth = doc.internal.pageSize.width - margin * 2;
  const firstColWidth = getWidthForLine(doc, headerFontSize, t("schedules.territory.s13.terr-no"));
  const oneFifteenthWidth = tableWidth / 5 / 3;
  const rowHeight = oneFifteenthWidth / 2 - mdLineThinkness - smLineThinkness;
  const secondColWidth = oneFifteenthWidth * 2;
  const colWidth = (tableWidth - firstColWidth - secondColWidth) / 8;

  const colStyles = () => {
    const styleObj: ColumnStyles = {};
    Array.from(Array(10).keys()).forEach((i) => {
      styleObj[i] = {
        cellWidth: i === 0 ? firstColWidth : i === 1 ? secondColWidth : colWidth,
        lineWidth: smLineThinkness,
      };
    });
    return styleObj;
  };

  const drawTable = (
    doc: jsPDF,
    startY: number,
    head: RowInput[],
    body: RowInput[],
    willDrawCell?: (hookData: CellHookData) => void,
  ) => {
    autoTable(doc, {
      theme: "grid",
      tableLineColor: "black",
      tableLineWidth: lgLineThinkness,
      startY: startY,
      head: head,
      body: body,
      margin: {
        left: margin,
        bottom: 0,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        fontSize: bodyFontSize,
        font: normalFont,
        cellPadding: { top: 0.04, bottom: 0.04, left: 0.05, right: 0.05 },
        halign: "center",
        valign: "middle",
        minCellHeight: rowHeight,
        lineColor: "black",
      },
      headStyles: {
        font: normalFont,
        fillColor: [209, 209, 209],
        textColor: "black",
        lineColor: "black",
        lineWidth: smLineThinkness,
        fontSize: headerFontSize,
        cellPadding: 0.05,
        halign: "center",
        valign: "middle",
        minCellHeight: rowHeight,
      },
      columnStyles: colStyles(),
      willDrawCell: willDrawCell,
      didDrawPage: (d) => {
        if (d.cursor) lastEndY = d.cursor.y;
      },
    });
  };

  const colWidths = [firstColWidth, secondColWidth, colWidth];
  const headers: RowInput[] = row(true, undefined, [], "", userMap, colWidths, doc);

  const theBody: RowInput[] = [];
  Array(rowsPerPage)
    .fill(0)
    .forEach((_, i) => {
      const p = pageRecords.at(i);
      theBody.push(...row(false, p?.terr, p?.records ?? [], p?.lastWorked ?? "", userMap, colWidths, doc));
    });

  drawTable(doc, initialY, headers, theBody);
  return lastEndY;
}

const row = (
  header: boolean,
  terr: Territory | undefined,
  recs: TerritoryRecord[],
  lastWorked: string,
  userMap: Map<number, User>,
  colWidths: number[],
  doc: jsPDF,
): RowInput[] => {
  const territoryNumber = t("schedules.territory.s13.terr-no");
  const dateLastCompleted = `${t("schedules.territory.s13.last-date-completed")}*`;
  const assignedTo = t("schedules.territory.s13.assigned-to");
  const dateAssigned = t("schedules.territory.s13.date-assigned");
  const dateCompleted = t("schedules.territory.s13.date-completed");

  // we need to compute the font size for the headers so that they will fit and not have line breaks in wrong places
  type fontSizes = {
    terrNo: number;
    lastCompleted: number;
    assignedTo: number;
    dateAssigned: number;
    dateCompleted: number;
    assignee: (name?: string) => number;
  };
  const minFontSize = 6;
  const fontSize: fontSizes = {
    terrNo: header
      ? // @ts-ignore noUncheckedIndexedAccess
        largestFontSize(doc, headerFontSize, minFontSize, longestLine(doc, territoryNumber), colWidths[0])
      : bodyFontSize,
    // @ts-ignore noUncheckedIndexedAccess
    lastCompleted: largestFontSize(doc, headerFontSize, minFontSize, longestLine(doc, dateLastCompleted), colWidths[1]),
    // @ts-ignore noUncheckedIndexedAccess
    assignedTo: largestFontSize(doc, headerFontSize, minFontSize, longestLine(doc, assignedTo), colWidths[2]),
    // @ts-ignore noUncheckedIndexedAccess
    dateAssigned: largestFontSize(doc, headerFontSize, minFontSize, longestLine(doc, dateAssigned), colWidths[2] / 2),
    // @ts-ignore noUncheckedIndexedAccess
    dateCompleted: largestFontSize(doc, headerFontSize, minFontSize, longestLine(doc, dateCompleted), colWidths[2] / 2),
    assignee: (name?: string): number => {
      if (!name) return minFontSize;
      // @ts-ignore noUncheckedIndexedAccess
      return largestFontSize(doc, headerFontSize, minFontSize, name, colWidths[2]);
    },
  };

  const terrNumberAndLocality = (): string => {
    const maxWidth = (colWidths[0] ?? 0.25) - 0.12;
    const terrNumber = truncateToWidth(doc, terr?.number.trim() ?? "", maxWidth ?? 0);
    const terrLocality = truncateToWidth(doc, terr?.locality.trim() ?? "", maxWidth ?? 0);
    return `${terrNumber}\n${terrLocality}`;
  };

  const terrNumberText = header ? territoryNumber : terrNumberAndLocality();

  return [
    [
      {
        content: terrNumberText,
        rowSpan: 2,
        styles: {
          lineWidth: { left: lgLineThinkness, right: smLineThinkness, bottom: mdLineThinkness, top: mdLineThinkness },
          fontSize: fontSize.terrNo,
        },
      },
      {
        content: header ? dateLastCompleted : stringToLocaleDate(lastWorked),
        rowSpan: 2,
        styles: {
          lineWidth: { right: lgLineThinkness, left: smLineThinkness, bottom: mdLineThinkness, top: mdLineThinkness },
          fontSize: fontSize.lastCompleted,
        },
      },
      {
        content: header ? assignedTo : (userMap.get(recs[0]?.user_id ?? 0)?.displayName ?? NBSP),
        colSpan: 2,
        styles: {
          lineWidth: { left: lgLineThinkness, right: mdLineThinkness, top: mdLineThinkness, bottom: smLineThinkness },
          fontSize: header ? fontSize.assignedTo : fontSize.assignee(userMap.get(recs[0]?.user_id ?? 0)?.displayName),
        },
      },
      {
        content: header ? assignedTo : (userMap.get(recs[1]?.user_id ?? 0)?.displayName ?? NBSP),
        colSpan: 2,
        styles: {
          lineWidth: { left: mdLineThinkness, right: mdLineThinkness, top: mdLineThinkness, bottom: smLineThinkness },
          fontSize: header ? fontSize.assignedTo : fontSize.assignee(userMap.get(recs[1]?.user_id ?? 0)?.displayName),
        },
      },
      {
        content: header ? assignedTo : (userMap.get(recs[2]?.user_id ?? 0)?.displayName ?? NBSP),
        colSpan: 2,
        styles: {
          lineWidth: { left: mdLineThinkness, right: mdLineThinkness, top: mdLineThinkness, bottom: smLineThinkness },
          fontSize: header ? fontSize.assignedTo : fontSize.assignee(userMap.get(recs[2]?.user_id ?? 0)?.displayName),
        },
      },
      {
        content: header ? assignedTo : (userMap.get(recs[3]?.user_id ?? 0)?.displayName ?? NBSP),
        colSpan: 2,
        styles: {
          lineWidth: { left: mdLineThinkness, right: mdLineThinkness, top: mdLineThinkness, bottom: smLineThinkness },
          fontSize: header ? fontSize.assignedTo : fontSize.assignee(userMap.get(recs[3]?.user_id ?? 0)?.displayName),
        },
      },
    ],
    [
      {
        content: header ? dateAssigned : stringToLocaleDate(recs[0]?.start ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: smLineThinkness, left: lgLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateAssigned,
        },
      },
      {
        content: header ? dateCompleted : stringToLocaleDate(recs[0]?.end ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: mdLineThinkness, left: smLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateCompleted,
        },
      },
      {
        content: header ? dateAssigned : stringToLocaleDate(recs[1]?.start ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: smLineThinkness, left: mdLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateAssigned,
        },
      },
      {
        content: header ? dateCompleted : stringToLocaleDate(recs[1]?.end ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: mdLineThinkness, left: smLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateCompleted,
        },
      },
      {
        content: header ? dateAssigned : stringToLocaleDate(recs[2]?.start ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: smLineThinkness, left: mdLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateAssigned,
        },
      },
      {
        content: header ? dateCompleted : stringToLocaleDate(recs[2]?.end ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: mdLineThinkness, left: smLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateCompleted,
        },
      },
      {
        content: header ? dateAssigned : stringToLocaleDate(recs[3]?.start ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: smLineThinkness, left: mdLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateAssigned,
        },
      },
      {
        content: header ? dateCompleted : stringToLocaleDate(recs[3]?.end ?? undefined) || NBSP,
        styles: {
          lineWidth: { right: mdLineThinkness, left: smLineThinkness, bottom: mdLineThinkness },
          fontSize: fontSize.dateCompleted,
        },
      },
    ],
  ];
};

async function getImageDimensions(src: Blob): Promise<{ width: number; height: number }> {
  const bmp = await createImageBitmap(src);
  const { width, height } = bmp;
  bmp.close();
  return { width: width, height: height };
}

// this creates a PDF of one territory, with its map and addresses, suitable for giving to a publisher
export async function singleTerritoryPDF(
  territory: Territory,
  addresses: TerritoryAddress[],
  cong: Cong,
  settings: CongSettings,
  imageBlob: Blob,
  orientation: PDFOrientation = "portrait",
  fullPage: boolean = false,
  kind: MapKind = MapKind.single,
  pageDimensions?: number[],
  records?: TerritoryRecord[],
) {
  const isPortrait = orientation === "portrait";
  const { doc, initY } =
    kind === MapKind.congregation
      ? await newPDFWithHeader(
          cong.name,
          i18n.t("schedules.territory.territories"),
          cong.locale.code,
          orientation,
          pageDimensions,
        )
      : await newPDFWithHeader(terrLabel(territory), cong.name, cong.locale.code, orientation);
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const docWidth = pageWidth - margin;
  doc.setFont(normalFont);
  doc.setFontSize(bodyFontSize);

  await addReturnByLabel(territory, settings, doc, records);

  let curY = initY;

  const addNotes = () => {
    if (!territory.notes) return;
    const txtLines: string[] = doc.splitTextToSize(territory.notes, docWidth - margin);
    doc.text(txtLines, margin, curY, { align: "left" });
    curY += getHeightForText(doc, bodyFontSize, txtLines.join("\n"), false);
  };

  addNotes();

  const addAttribution = () => {
    // add the attribution overlay, since the canvas in maplibre won't have it
    const origFontSize = doc.getFontSize();
    const origFillColor = doc.getFillColor();

    // add an element so that we can get the innerText of the HTML attribution
    const attribEl = document.createElement("div");
    attribEl.innerHTML = tileProvider(settings).attribution;
    const attributionText = attribEl.textContent || attribEl.innerText;
    attribEl.remove();

    doc.setFontSize(7);
    const textDim = doc.getTextDimensions(attributionText);
    const textHeight = textDim.h + 0.05;

    doc.saveGraphicsState();
    // @ts-ignore bad type def
    doc.setGState(new doc.GState({ opacity: 0.3 }));
    doc.setFillColor(255, 255, 255);
    doc.rect(margin, curY - textHeight, textDim.w + 0.1, textHeight, "F");
    doc.restoreGraphicsState();
    doc.text(attributionText, margin + 0.02, curY - textHeight / 2, { baseline: "middle", align: "left" });

    doc.setFillColor(origFillColor);
    doc.setFontSize(origFontSize);
  };

  let imageWidth = pageWidth - 1;
  // we want to maintain the aspect ratio
  const dimensions = await getImageDimensions(imageBlob);
  const aspectRatio = dimensions.width / dimensions.height;
  const calcImageHeight = (): number => {
    if (kind === MapKind.congregation) {
      return isPortrait ? imageWidth / aspectRatio : pageHeight - curY - 0.6;
    }
    if (isPortrait) return imageWidth / aspectRatio;
    return fullPage ? pageHeight - curY - 0.6 : pageHeight * 0.75 - curY - 0.1;
  };
  const imageHeight = calcImageHeight();
  if (!isPortrait) imageWidth = imageHeight * aspectRatio;

  doc.addImage(
    URL.createObjectURL(imageBlob),
    "PNG",
    (pageWidth - imageWidth) / 2,
    curY,
    imageWidth,
    imageHeight,
    "",
    "FAST",
  );
  curY += imageHeight;
  addAttribution();
  curY += 0.15;

  const tableBodyFontSize = bodyFontSize - 1;
  let notesWidth = pageWidth / 5;
  addresses.forEach((a) => {
    const width = getWidthForLine(doc, tableBodyFontSize, a.notes ?? "");
    if (width > notesWidth) notesWidth = width;
  });
  // don't let the notes column get bigger than 1/3 of the page width
  if (notesWidth > pageWidth / 3) notesWidth = pageWidth / 3;
  // we have two side by side address tables in landscape mode, so cut the notes width in half
  if (!isPortrait) notesWidth /= 2;

  const drawTable = (
    doc: jsPDF,
    startY: number,
    startX: number,
    tableWidth: number,
    header: RowInput[],
    body: RowInput[],
  ) => {
    const notesCol = settings.terr_address_hide_type ? 3 : 4;
    autoTable(doc, {
      theme: "striped",
      startY,
      tableWidth,
      head: header,
      headStyles: {
        fontSize: headerFontSize,
        font: boldFont,
        fontStyle: "bold",
      },
      body: body,
      margin: {
        left: startX,
        right: margin,
      },
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      styles: {
        fontSize: tableBodyFontSize,
        font: normalFont,
        cellPadding: {
          top: 0.015,
          bottom: 0.015,
          left: 0.02,
          right: 0.02,
        },
      },
      columnStyles: {
        [notesCol]: {
          cellWidth: notesWidth,
        },
      },
      didDrawPage: (d) => {
        if (d.cursor) curY = d.cursor.y;
      },
    });
  };

  const hideType = settings.terr_address_hide_type;
  const hideNotes = !isPortrait && !addresses.filter((a) => a.notes !== null).length;

  const headerRow: string[] = [
    t("contact.address"),
    t("general.date"),
    ...(hideType ? [] : [t("general.type")]),
    t("congprofile.language"),
    ...(hideNotes ? [] : [t("userinfo.emergency.notes")]),
  ];

  const header: RowInput[] = [headerRow];

  const addressStatus = (ta: TerritoryAddress): string => {
    const items = [];
    if (ta.dnc) items.push(t("schedules.territory.do-not-call"));
    if (ta.locationType === TerritoryAddressLocationType.Business) items.push(t("schedules.territory.business"));
    else if (ta.locationType === TerritoryAddressLocationType.Custom) items.push(t("general.custom"));
    return items.join(", ");
  };

  const addrNotes = (a: TerritoryAddress): string | null => {
    let value = "";
    if (a.notes?.trim()) value = a.notes.trim();
    if (a.name?.trim()) value += "\n" + a.name.trim();
    if (a.phone?.trim()) value += " " + a.phone.trim();
    if (!value) return null;
    return value;
  };

  const body: RowInput[] = addresses.map((a) => {
    const pinLabel = a.pinLabel ? `${a.pinLabel} | ` : "";

    return [
      `${pinLabel}${addressToString(a)}`,
      a.lastWorked ? stringToLocaleDate(a.lastWorked) : null,
      ...(hideType ? [] : [addressStatus(a)]),
      a.lang ? (localizedLanguageName(i18n.language, a.lang) ?? null) : null,
      ...(hideNotes ? [] : [addrNotes(a)]),
    ];
  });

  if (body.length > 0 && kind === MapKind.single) {
    // only show the addresses header if we actually have addresses to display
    if (isPortrait) {
      drawTable(doc, curY, margin, pageWidth - margin * 2, header, body);
    } else {
      //split to two columns
      const tableWidth = (pageWidth - margin * 2) / 2 - 0.1;
      const startY = curY;
      const col1 = body.splice(0, Math.ceil(addresses.length / 2));
      const pageNum = doc.getNumberOfPages();
      drawTable(doc, startY, margin, tableWidth, header, col1);
      doc.setPage(pageNum);
      drawTable(doc, startY, margin + tableWidth + 0.1, tableWidth, header, body);
    }
  }

  doc.save(`${territoryFilename(territory)}.pdf`);
}

async function addReturnByLabel(territory: Territory, settings: CongSettings, doc: jsPDF, records?: TerritoryRecord[]) {
  if (!records) {
    try {
      records = await territoryApi.getRecords(true);
    } catch (err: any) {
      console.error("error getting territory records for return by label", err);
      HGBugsnagNotify("addReturnByLabel", err);
    }
  }
  const assignment = records?.find((r) => r.territory_id === territory.id && r.end === null);
  if (assignment) {
    const start = stringToDate(assignment.start!);
    const end = getDayjs(start).add(settings.terr_checkout_duration_days, "day");
    const endStr = stringToLongLocaleDate(dateToString(end.toDate()));
    PDFFooterLeft(
      doc,
      `${t("schedules.territory.return-by", { interpolation: PythonI18nInterpolation, date: endStr })}`,
    );
  }
}
