import { HourglassTour } from "./tour";
import { useContext } from "react";
import { HGContext } from "../helpers/globals";

export default function MainContent(props: any) {
  const ctx = useContext(HGContext);
  const showTour = ctx.globals.flags.tour;

  return (
    <div className="flex-grow-1" id="mainContent">
      {showTour && <HourglassTour userId={ctx.globals.authUser.id} />}
      {props.children}
    </div>
  );
}
