import { Feature, FeatureCollection, MultiPolygon, Polygon, Position } from "geojson";
import * as L from "leaflet";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FeatureGroup } from "react-leaflet";
import { GeomanControls } from "../../../@adapters/react-leaflet-geoman";
import { geoJsonPolygons } from "../../../helpers/geo";
import { HGLayerType } from "../../../types/scheduling/territory";
import { useLeafletContext } from "@react-leaflet/core";
import useDeepCompareEffectNoCheck from "use-deep-compare-effect";

const TextareaBackground = "rgba(255, 244, 52,0.6)";

export default function Geoman(props: {
  geojson?: MultiPolygon;
  setGeojson: (boundaries: MultiPolygon, annotations?: FeatureCollection) => void;
  annotations?: FeatureCollection;
  noEdit?: boolean;
}) {
  const ref = useRef<L.FeatureGroup>(null);
  const { t, i18n } = useTranslation();
  const [, setUpdatedLayer] = useState<MultiPolygon>();
  const [, setUpdatedAnnotations] = useState<FeatureCollection>();

  const leafletContext = useLeafletContext();
  const [lastGeoJson, setLastGeoJson] = useState<MultiPolygon | undefined>(props.geojson);
  const lastGeoJsonRef = useRef(lastGeoJson);
  lastGeoJsonRef.current = lastGeoJson;
  const [lastAnnotation, setLastAnnotation] = useState<FeatureCollection | undefined>();
  const lastAnnotationRef = useRef(lastAnnotation);
  lastAnnotationRef.current = lastAnnotation;

  useDeepCompareEffectNoCheck(() => {
    if (ref.current?.getLayers().length) {
      // clear out any existing geoman layers
      try {
        ref.current?.clearLayers();
      } catch (err: any) {
        console.error(err);
      }
    }

    if (props.geojson) {
      L.geoJSON(props.geojson).eachLayer((layer) => {
        if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
          const layerGeoJson = layer.toGeoJSON();
          if (layerGeoJson.geometry.type === "MultiPolygon") {
            // convert the MultiPolygon into an array of Polygons and add each as a layer
            const polygons = geoJsonPolygons(layerGeoJson.geometry);
            polygons.forEach((p) => {
              L.geoJSON(p).eachLayer((l) => {
                l.options.pmIgnore = false;
                // @ts-ignore custom
                l.hgType = HGLayerType.Boundary;
                try {
                  ref.current?.addLayer(l);
                  L.PM.reInitLayer(l);
                } catch (err: any) {
                  console.error("error adding geoman layer", err);
                }
              });
            });
          } else {
            layer.options.pmIgnore = false;
            // @ts-ignore custom
            layer.hgType = HGLayerType.Boundary;
            ref.current?.addLayer(layer);
            L.PM.reInitLayer(layer);
          }
        } else {
          console.log("did not get poly layer", layer);
        }
        // basically we need to force a re-render here
        setUpdatedLayer(props.geojson);
      });
    }

    if (props.annotations) {
      L.geoJSON(props.annotations).eachLayer((layer) => {
        let newLayer = layer;

        if (layer instanceof L.Marker || layer instanceof L.Polyline) {
          if (layer instanceof L.Marker && layer.feature?.properties?.text) {
            newLayer = L.marker(layer.getLatLng(), {
              textMarker: true,
              text: layer.feature.properties.text,
            });

            try {
              // @ts-ignore pm should be there
              newLayer.pm.getElement().style.background = TextareaBackground;
            } catch {
              console.warn("unable to set background on text layer");
            }
          }
          newLayer.options.pmIgnore = false;
          // @ts-ignore custom
          newLayer.hgType = HGLayerType.Annotation;

          if (layer instanceof L.Polyline) newLayer;
          ref.current?.addLayer(newLayer);
          // L.PM.reInitLayer(layer);
        }
      });

      setUpdatedAnnotations(props.annotations);
    }
  }, [props.geojson || {}, props.annotations]);

  const saveCurrent = () => {
    const polygonLayers: L.Polygon[] = [];
    const annotationLayers: Feature[] = [];
    ref.current?.getLayers().forEach((layer) => {
      if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
        polygonLayers.push(layer);
      } else if (layer instanceof L.Marker || layer instanceof L.Polyline) {
        const geoLayer = layer.toGeoJSON();
        geoLayer.properties = { hgType: HGLayerType.Annotation };
        if (layer instanceof L.Marker) {
          try {
            geoLayer.properties.text = layer.pm.getText();
            annotationLayers.push(geoLayer);
          } catch (e) {
            console.warn("issue with marker layer", e, layer.pm);
          }
        } else {
          annotationLayers.push(geoLayer);
        }
      }
    });

    // take the polygon layers and combine into a multipolygon
    const coords: Position[][][] = polygonLayers
      .map((l) => l.toGeoJSON())
      .filter((g): g is Feature<Polygon> => g.geometry.type === "Polygon")
      .map((g) => g.geometry.coordinates);

    const newGeojson: MultiPolygon = { type: "MultiPolygon", coordinates: coords };
    setLastGeoJson(newGeojson);
    const newAnnotation: FeatureCollection = { type: "FeatureCollection", features: annotationLayers };
    setLastAnnotation(newAnnotation);

    if (polygonLayers.length === 0 && props.geojson !== undefined) {
      // this is some kind of race condition/bad state with text onBlur; we need to avoid setting newGeojson to undefined
      return;
    }
    props.setGeojson(newGeojson, annotationLayers.length > 0 ? newAnnotation : undefined);
  };

  const onCreate = (e: { shape: string; layer: L.Layer }) => {
    // e.layer.options.pmIgnore = false;
    // L.PM.reInitLayer(e.layer);

    // text labels are L.Markers. We don't want to save them when they are created because the text hasn't been entered yet.
    // saveCurrent is called based on the textBlur handler.
    if (!(e.layer instanceof L.Marker)) {
      saveCurrent();
    } else {
      e.layer.pm.getElement().style.background = TextareaBackground;
    }
  };

  const canEditAnnotations = true;

  const modeToggled = (kind: "edit" | "drag" | "remove") => {
    return function (event: { enabled: boolean; map: L.Map }) {
      event.map.eachLayer((l) => {
        if (
          l.options.pmIgnore !== false &&
          (l instanceof L.Polygon || l instanceof L.Rectangle || l instanceof L.Marker || l instanceof L.Polyline) &&
          l.pm
        ) {
          // disable edit on all the other layers
          switch (kind) {
            case "edit":
              l.pm.disable();
              break;
            case "drag":
              l.pm.disableLayerDrag();
              break;
          }
        }
      });

      const controlName = (): string => {
        switch (kind) {
          case "edit":
            return "Edit";
          case "drag":
            return "Drag";
          case "remove":
            return "Removal";
        }
      };

      const stopEditing = () => {
        switch (kind) {
          case "edit":
            event.map.pm.disableGlobalEditMode();
            break;
          case "drag":
            event.map.pm.disableGlobalDragMode();
            break;
          case "remove":
            event.map.pm.disableGlobalRemovalMode();
            break;
        }
      };

      event.map.pm.Toolbar.changeActionsOfControl(controlName(), [
        {
          text: t("general.cancel"),
          onClick: () => {
            const lastBoundaries = lastGeoJsonRef.current ? lastGeoJsonRef.current : props.geojson;
            if (lastBoundaries) props.setGeojson(lastBoundaries, lastAnnotationRef.current ?? props.annotations);
            stopEditing();
          },
        },
        {
          text: t("popup.error.button.ok"),
          onClick: () => {
            saveCurrent();
            stopEditing();
          },
        },
      ]);
    };
  };

  return (
    <FeatureGroup ref={ref}>
      {!props.noEdit && (
        <GeomanControls
          pathOptions={{ stroke: false }}
          lang={i18n.language as L.PM.SupportLocales}
          options={{
            position: "topleft",
            drawText: canEditAnnotations,
            drawMarker: false,
            drawPolyline: canEditAnnotations,
            drawCircle: false,
            drawCircleMarker: false,
            cutPolygon: false,
            rotateMode: false,
            drawRectangle: true,
            drawPolygon: true,
          }}
          globalOptions={{
            continueDrawing: false,
            // editable: false,
            // markerEditable: true,
            // allowEditing: true,
            draggable: true,
            allowRemoval: true,
          }}
          onMount={() =>
            leafletContext.map.pm.Toolbar.changeControlOrder([
              "drawPolygon",
              "drawRectangle",
              "drawPolyline",
              "drawText",
              "editMode",
              "dragMode",
              "removalMode",
            ])
          }
          // onUnmount={() => L.PM.setOptIn(false)}
          // eventDebugFn={(input?: any) => console.log("debug", input)}
          onCreate={onCreate}
          onTextBlur={(e) => {
            if (e.layer instanceof L.Marker) {
              try {
                if (!e.layer.pm.getText()) return;
              } catch {
                //noop
              }
            }
            saveCurrent();
          }}
          // onDrawEnd={(e) => {}}
          // onChange={handleChange}
          // onMapRemove={() => console.log("map remove")}
          // onDragEnd={() => console.log("drag end")}
          // onUpdate={handleChange}
          // onEdit={handleChange}
          // onMapRemove={handleChange}
          // onMapCut={handleChange}
          // onDragEnd={handleChange}
          // onMarkerDragEnd={handleChange}
          // onLayerRemove={handleChange}
          onGlobalEditModeToggled={modeToggled("edit")}
          onGlobalRemovalModeToggled={modeToggled("remove")}
          onGlobalDragModeToggled={modeToggled("drag")}
        />
      )}
    </FeatureGroup>
  );
}
