import "../../css/territory.css";
import { useCongSettings } from "../../api/cong";
import { useContext, useState } from "react";
import { Map as MaplibreMap } from "maplibre-gl";
import { HGContext } from "../../helpers/globals";
import { addressesForPDF, mapFontStack, PrintOptions, terrLabel } from "../congregation/territory/common";
import { PDFOrientation } from "../../types/paper";
import { territoryAddressCompare } from "../../helpers/address";
import { MapGenerator, MapKind } from "../../@adapters/map-export/mapglExport";
import { singleTerritoryPDF } from "../congregation/territory/pdf";
import { HGBugsnagNotify } from "../../helpers/bugsnag";
import { Container } from "react-bootstrap";
import { TagList } from "../congregation/tags";
import { TerritoryPrintOptionsMenu } from "../congregation/territory/printOptionsMenu";
import { MapWithGeoJson } from "../congregation/territory/maps";
import { useMyTerritories } from "../../api/territory";
import { useParams } from "react-router";
import { UserParams } from "../../types/user";
import { AddressesTable } from "../congregation/territory/territoryAddresses";

export default function PublisherTerritory() {
  const congSettings = useCongSettings();
  const [mapLibreMap, setMapLibreMap] = useState<MaplibreMap>();
  const [showPrintSpinner, setShowPrintSpinner] = useState(false);
  const [printDropdownShow, setPrintDropdownShow] = useState(false);
  const myCong = useContext(HGContext).globals.cong;
  const [printOptions, setPrintOptions] = useState<PrintOptions>({
    excludeHideOnMapAddresses: false,
    excludeAllAddresses: false,
    dncOnly: false,
    invertedShading: false,
    mapFullPage: false,
  });
  const { id } = useParams() as UserParams;
  const terrId = parseInt(id);
  const territory = useMyTerritories().data?.find((t) => t.territory.id === terrId)?.territory;

  if (!congSettings.data || !territory) return null;

  const printTerritory = async (orientation: PDFOrientation) => {
    if (!mapLibreMap) return;
    setShowPrintSpinner(true);
    const pdfAddrs = addressesForPDF(territory.addresses ?? [], printOptions).sort(territoryAddressCompare);
    try {
      // create the offscreen drawing
      const mapGen = new MapGenerator(
        mapLibreMap,
        boundaries,
        annotations,
        pdfAddrs.filter((a) => !a.hideOnMap),
        mapFontStack(congSettings.data),
        orientation,
        printOptions.invertedShading,
        printOptions.mapFullPage,
      );
      const printImageBlob = await mapGen.generate();
      await singleTerritoryPDF(
        territory,
        pdfAddrs,
        myCong!,
        congSettings.data,
        printImageBlob,
        orientation,
        printOptions.mapFullPage,
        MapKind.single,
        undefined,
        [],
      );
    } catch (err: any) {
      console.error("publisher print map", err);
      HGBugsnagNotify("publisherPrintTerritory", err);
    } finally {
      setShowPrintSpinner(false);
    }
  };

  const boundaries = territory.boundaries ? JSON.parse(territory.boundaries) : undefined;
  const annotations = territory.annotations ? JSON.parse(territory.annotations) : undefined;
  const mapNotReady = !!boundaries && !mapLibreMap;
  const addresses = territory.addresses?.sort(territoryAddressCompare)?.map((a, i) => ({ ...a, pinLabel: `${i + 1}` }));

  return (
    <Container className="mt-3">
      <div className="d-flex justify-content-between">
        <div>
          <h4>{terrLabel(territory)}</h4>
          <p className="whitespace-pre">{territory.notes}</p>
        </div>
        <TagList tags={territory.tags ?? []} />
        <TerritoryPrintOptionsMenu
          show={printDropdownShow}
          showSpinner={showPrintSpinner}
          setShow={setPrintDropdownShow}
          printOptions={printOptions}
          setPrintOptions={setPrintOptions}
          printTerritory={printTerritory}
          mapNotReady={mapNotReady}
          className=""
        />
      </div>

      {(!!boundaries || !!annotations) && (
        <div className="mt-3">
          <MapWithGeoJson
            key={territory.id}
            addresses={addresses}
            settings={congSettings.data}
            geojson={boundaries}
            setMaplibreMap={setMapLibreMap}
            territory={territory}
            navigateToMap={() => {}}
            pubMode
            fullHeight
          />
        </div>
      )}

      {!!addresses?.length && (
        <AddressesTable
          key={addresses.length}
          territory={territory}
          setTerritory={() => {}}
          addresses={addresses}
          updateAddresses={() => {}}
          noScroll
        />
      )}
    </Container>
  );
}
