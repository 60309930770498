import type { GeomanHandlers } from "../types";

export const reference = [
  "onMapRemove",
  "onLayerRemove",
  "onMapCut",
  "onLayerCut",
  "onMapRotateEnable",
  "onLayerRotateEnable",
  "onMapRotateDisable",
  "onLayerRotateDisable",
  "onMapRotateStart",
  "onLayerRotateStart",
  "onMapRotate",
  "onLayerRotate",
  "onMapRotateEnd",
  "onLayerRotateEnd",
  "onGlobalDrawModeToggled",
  "onDrawStart",
  "onDrawEnd",
  "onCreate",
  "onGlobalEditModeToggled",
  "onGlobalDragModeToggled",
  "onGlobalRemovalModeToggled",
  "onGlobalCutModeToggled",
  "onGlobalRotateModeToggled",
  "onLangChange",
  "onButtonClick",
  "onActionClick",
  "onKeyEvent",
  "onSnapDrag",
  "onSnap",
  "onUnsnap",
  "onCenterPlaced",
  "onEdit",
  "onUpdate",
  "onEnable",
  "onDisable",
  "onVertexAdded",
  "onVertexRemoved",
  "onVertexClick",
  "onMarkerDragStart",
  "onMarkerDrag",
  "onMarkerDragEnd",
  "onLayerReset",
  "onIntersect",
  "onChange",
  "onTextChange",
  "onTextBlur",
  "onDragStart",
  "onDrag",
  "onDragEnd",
  "onDragEnable",
  "onDragDisable",
] as (keyof GeomanHandlers)[];
