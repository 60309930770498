import { Dropdown, Form, Spinner } from "react-bootstrap";
import { ArrowsExpand, Printer } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { BsFileSpreadsheet } from "react-icons/bs";
import { PDFOrientation } from "../../../types/paper";
import { PrintOptions } from "./common";

export function TerritoryPrintOptionsMenu(props: {
  show: boolean;
  showSpinner: boolean;
  setShow: (show: boolean) => void;
  printOptions: PrintOptions;
  setPrintOptions: (printOptions: PrintOptions) => void;
  printTerritory: (orientation: PDFOrientation) => Promise<void>;
  disabled?: boolean;
  mapNotReady?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <Dropdown className={props.className ?? "ms-2"} autoClose="outside" show={props.show} onToggle={props.setShow}>
      <Dropdown.Toggle variant="secondary" disabled={props.disabled || props.mapNotReady || props.showSpinner}>
        {props.showSpinner ? <Spinner size="sm" /> : <Printer />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={async () => {
            props.setShow(false);
            await props.printTerritory("portrait");
          }}
        >
          <BsFileSpreadsheet size="1.2em" /> {t("schedules.territory.territory")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={async () => {
            props.setShow(false);
            await props.printTerritory("landscape");
          }}
        >
          <BsFileSpreadsheet size="1.2em" className="rotate-90" /> {t("schedules.territory.territory")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          disabled={props.printOptions.excludeAllAddresses}
          onClick={() =>
            props.setPrintOptions({
              ...props.printOptions,
              excludeHideOnMapAddresses: !props.printOptions.excludeHideOnMapAddresses,
            })
          }
        >
          <Form.Check
            checked={props.printOptions.excludeHideOnMapAddresses}
            label={t("schedules.territory.address.exclude-hidden-print")}
            readOnly
          />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            const newVal = !props.printOptions.excludeAllAddresses;
            props.setPrintOptions({
              ...props.printOptions,
              excludeAllAddresses: newVal,
              dncOnly: newVal ? false : props.printOptions.dncOnly,
              excludeHideOnMapAddresses: newVal ? false : props.printOptions.excludeHideOnMapAddresses,
            });
          }}
        >
          <Form.Check
            checked={props.printOptions.excludeAllAddresses}
            label={t("schedules.territory.address.exclude-all-print")}
            readOnly
          />
        </Dropdown.Item>
        <Dropdown.Item
          disabled={props.printOptions.excludeAllAddresses}
          onClick={() =>
            props.setPrintOptions({
              ...props.printOptions,
              dncOnly: !props.printOptions.dncOnly,
            })
          }
        >
          <Form.Check checked={props.printOptions.dncOnly} label={t("schedules.territory.address.dnc-only")} readOnly />
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() =>
            props.setPrintOptions({
              ...props.printOptions,
              invertedShading: !props.printOptions.invertedShading,
            })
          }
        >
          <Form.Check
            checked={props.printOptions.invertedShading}
            label={t("settings.territory.inverted-shading")}
            readOnly
          />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            props.setPrintOptions({
              ...props.printOptions,
              mapFullPage: !props.printOptions.mapFullPage,
            })
          }
        >
          <Form.Check
            checked={props.printOptions.mapFullPage}
            label={
              <span>
                <ArrowsExpand className="me-1" />
                {t("general.map")}
              </span>
            }
            readOnly
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
