import { forwardRef } from "react";
import { Props } from "./CalendarToday";

const ExclamationDiamondFillBlack = forwardRef<SVGSVGElement, Props>(({ color = "#eed202", size = "1em", title, ...rest }, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill="black"
      {...rest}
    >
      {title ? <title>{title}</title> : null}

      <path
        d="M15.6,7L9,0.4c-0.5-0.5-1.5-0.5-2,0L0.4,7c-0.6,0.6-0.6,1.5,0,2.1L7,15.6c0.6,0.6,1.5,0.6,2.1,0L15.6,9
	C16.1,8.5,16.1,7.5,15.6,7z"
      />
      <path
        fill={color}
        d="M14.9,7.7L8.3,1.1C8.2,1,8.1,1,8,1S7.8,1,7.7,1.1L1.1,7.7C1,7.8,1,7.9,1,8c0,0.1,0,0.2,0.1,0.3l6.5,6.5
	C7.8,15,7.9,15,8,15c0.1,0,0.2,0,0.3-0.1l6.5-6.5C15,8.2,15,8.1,15,8C15,7.9,15,7.8,14.9,7.7z M7.9,4C7.9,4,8,4,7.9,4
	c0.6,0,1,0.5,1,1L8.5,8.5C8.5,8.8,8.3,9,8,9S7.5,8.8,7.5,8.5L7.1,5C7.1,4.5,7.4,4.1,7.9,4z M8,12c-0.6,0-1-0.4-1-1s0.4-1,1-1
	s1,0.4,1,1S8.6,12,8,12z"
      />
    </svg>
  );
});


export default ExclamationDiamondFillBlack;
