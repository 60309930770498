import { jsPDF } from "jspdf";
import urljoin from "url-join";
import { PaperSize, PDFOrientation } from "../types/paper";
import User from "../types/user";
import { dateToServiceYear } from "./dateHelpers";
import { arrayBufferToBase64 } from "./e2e";
import HourglassGlobals from "./globals";

export type Group = {
  id: number;
  overseer: User;
};

export const [halfInch, topMargin, leftMargin] = [0.5, 0.35, 0.25];
export const [space, boxSide] = [0.2, 0.1];
export const [normalFont, boldFont] = ["hgNormal2", "hgBold2"];
export const [normalStyle, boldStyle] = ["normal", "bold"];
export const fontBaseUrl = "/fonts/";

export function getFontFiles(locale: string, sans = false): string[] {
  switch (locale.substring(0, 2).toLowerCase()) {
    case "ko":
    case "ja":
    case "zh":
      return ["GoNotoCJKCore-Regular.ttf", "GoNotoCJKCore-Bold.ttf"];
    case "hy":
    case "ka":
      return ["GoNotoEuropeAmericas-Regular.ttf", "GoNotoEuropeAmericas-Bold.ttf"];
    case "fil":
    case "tl":
      return ["GoNotoSouthEastAsia-Regular.ttf", "GoNotoSouthEastAsia-Bold.ttf"];
    case "gu":
    case "hi":
    case "ne":
      return ["GoNotoSouthAsia.ttf", "GoNotoSouthAsia.ttf"];
    case "th":
      //note we aren't actually using the Noto Thai font because it doesn't include basic roman characters
      return ["NotoSerifThai-Regular.ttf", "NotoSerifThai-Bold.ttf"];
    default:
      if (sans) return ["NotoSans-Regular.ttf", "NotoSans-Bold.ttf"];
      return ["NotoSerif-Regular.ttf", "NotoSerif-Bold.ttf"];
  }
}

export function serviceYear(y: number, m: number): number {
  if (m >= 9 && m <= 12) return y + 1;
  return y;
}

// given a string which could span multiple lines, find the largest width and return it.
// "reallylongline\nshort" would return the getTextWidth of "reallylongline"
export function getWidthForLine(doc: jsPDF, fontSize: number, multiLine: string, bold = true, padding = space): number {
  const font = doc.getFont();
  const curFontSize = doc.getFontSize();
  if (bold) doc.setFont(boldFont, boldStyle);
  else doc.setFont(normalFont, normalStyle);
  doc.setFontSize(fontSize);

  //at least allow 5 characters. this helps with very short strings such as "Ore" in Italian only allowing 3-4 digits
  const minWidth = doc.getTextWidth("wwwww");

  let maxLen = 0;
  const lines = multiLine.split("\n");
  lines.forEach((line) => {
    const len = Math.max(doc.getTextWidth(line), minWidth);
    if (len > maxLen) maxLen = len;
  });

  doc.setFontSize(curFontSize);
  doc.setFont(font.fontName, font.fontStyle);
  return maxLen + padding;
}

//given a string which could span multiple lines, return the sum of all the line heights
export function getHeightForText(doc: jsPDF, fontSize: number, multiLine: string, bold = true): number {
  const font = doc.getFont();
  const curFontSize = doc.getFontSize();
  if (bold) doc.setFont(boldFont, boldStyle);
  else doc.setFont(normalFont, normalStyle);
  doc.setFontSize(fontSize);

  //at least allow 5 characters. this helps with very short strings such as "Ore" in Italian only allowing 3-4 digits
  const minHeight = doc.getTextWidth("I");

  let totalHeight = 0;
  const lines = multiLine.split("\n");
  const adjustment = 0.013;
  lines.forEach((line) => {
    totalHeight += Math.max(doc.getTextDimensions(line).h, minHeight) + adjustment;
  });

  doc.setFontSize(curFontSize);
  doc.setFont(font.fontName, font.fontStyle);
  return totalHeight;
}

// return the longest line in a multiline string based on the actual displayed width
export function longestLine(doc: jsPDF, multiLine: string): string {
  let maxLen = 0;
  let longest = "";
  const lines = multiLine.split("\n");
  lines.forEach((line) => {
    const len = doc.getTextWidth(line);
    if (len > maxLen) {
      maxLen = len;
      longest = line;
    }
  });

  return longest;
}

export async function loadFont(doc: jsPDF, baseUrl: string, name: string, bold: boolean): Promise<any> {
  try {
    const resp = await fetch(urljoin(baseUrl, name));
    if (!resp.ok) throw new Error(`fetch font response not ok: ${resp.status}`);
    const buf = await resp.arrayBuffer();
    doc.addFileToVFS(name, arrayBufferToBase64(buf));
    if (bold) doc.addFont(name, boldFont, boldStyle);
    else doc.addFont(name, normalFont, normalStyle);
  } catch (err) {
    console.error("error downloading PDF font", err);
    throw err;
  }
}

interface NewPDFProps {
  title: string;
  localeCode: string;
  orientation?: PDFOrientation;
  sans?: boolean;
  paperSize?: PaperSize | number[];
}

export async function newPDF({
  title,
  localeCode,
  orientation = "portrait",
  sans = true,
  paperSize = HourglassGlobals.cong!.country.paper_size,
}: NewPDFProps): Promise<jsPDF> {
  const doc: jsPDF = new jsPDF({
    unit: "in",
    format: paperSize,
    orientation: orientation,
    compress: true,
  });

  doc.setProperties({
    title: title,
  });

  // select font based on congregation, not user's preference: meeting parts and names may be in a different
  // character set
  const [regularFile, boldFile] = getFontFiles(localeCode, sans);
  const fontBase = urljoin(HourglassGlobals.staticUrl, fontBaseUrl);
  // @ts-ignore noUncheckedIndexedAccess
  await Promise.all([loadFont(doc, fontBase, regularFile, false), loadFont(doc, fontBase, boldFile, true)]);

  return doc;
}

type NewPDF = {
  doc: jsPDF;
  initY: number;
};

//this helps us create new PDFs consistently, using the same header
export async function newPDFWithHeader(
  rightTitle: string,
  leftTitle?: string,
  localeCode: string = HourglassGlobals.cong!.locale.code,
  orientation: PDFOrientation = "portrait",
  dimensions?: number[],
): Promise<NewPDF> {
  const doc = await newPDF({ title: rightTitle, localeCode, orientation, paperSize: dimensions });
  doc.setLanguage(localeCode as any);
  const initY = PDFHeader(doc, rightTitle, leftTitle);
  PDFFooter(doc);

  return { doc, initY };
}

export function PDFHeader(doc: jsPDF, rightTitle: string, leftTitle?: string): number {
  const margin = halfInch;
  const width = doc.internal.pageSize.width;

  const origFontSize = doc.getFontSize();
  doc.setFont(boldFont, boldStyle);
  // find the font size where both titles will fit on the same line
  const fontSize = largestFontSize(doc, 12, 8, `${leftTitle ?? ""}  ${rightTitle}`, width - margin * 2);
  doc.setFontSize(fontSize);
  doc.text(rightTitle, width - margin, margin + 0.1, { align: "right" });
  if (leftTitle) {
    doc.text(leftTitle, margin, margin + 0.1, { align: "left" });
  }
  const titleDim = doc.getTextDimensions(rightTitle);
  const initY = margin + titleDim.h;
  doc.setDrawColor("black");
  doc.setLineWidth(0.01);
  doc.line(margin, initY, width - margin, initY);

  doc.setFont(normalFont, normalStyle);
  doc.setFontSize(origFontSize);

  return initY + 0.1; // adds a margin below the header
}

export function S13Header(
  doc: jsPDF,
  titleStr: string,
  svcYearTitle: string,
  svcYear: number,
  businessStr: string,
): number {
  const margin = halfInch;
  const width = 8.27; // A4 width
  const underlineLength = 0.75;

  const origFontSize = doc.getFontSize();
  doc.setFont(boldFont, boldStyle);
  doc.setFontSize(14);
  doc.text(titleStr, width / 2, margin, { align: "center" });
  const titleDim = doc.getTextDimensions(titleStr);
  doc.setFontSize(10);
  doc.text(`${svcYearTitle}: `, margin, margin + 0.4, { align: "left" });
  const svcYearStrDim = doc.getTextDimensions(`${svcYearTitle}: `);

  doc.setFont(normalFont, normalStyle);
  doc.text(
    `${svcYear ?? dateToServiceYear(new Date())}`,
    margin + svcYearStrDim.w + underlineLength / 2,
    margin + 0.4,
    {
      align: "center",
    },
  );

  doc.setDrawColor("black");
  doc.setLineWidth(0.01);
  doc.line(svcYearStrDim.w + margin, margin + 0.42, margin + svcYearStrDim.w + underlineLength, margin + 0.42);

  doc.setFont(boldFont, boldStyle);
  if (businessStr) doc.text(businessStr, width - margin, margin + 0.4, { align: "right" });

  const initY = margin + titleDim.h * 2;
  doc.setFont(normalFont, normalStyle);
  doc.setFontSize(origFontSize);

  return initY + 0.1; // adds a margin below the header
}

export function PDFFooter(doc: jsPDF) {
  // put the current date & time at the bottom

  const margin = halfInch;
  const width = doc.internal.pageSize.width;
  const height = doc.internal.pageSize.height;
  const origFontSize = doc.getFontSize();

  const timestamp = new Date().toLocaleString();
  doc.setFontSize(8);
  const textWidth = doc.getTextWidth(timestamp);
  doc.text(timestamp, width - margin - textWidth, height - margin);
  doc.setFontSize(origFontSize);
}

export function PDFFooterLeft(doc: jsPDF, text: string) {
  // put some key text at the bottom

  const margin = halfInch;
  const height = doc.internal.pageSize.height;
  const origFontSize = doc.getFontSize();

  doc.setFontSize(8);
  doc.text(text, margin, height - margin);
  doc.setFontSize(origFontSize);
}

// find the largest font size, starting at startSize and working down .5 point at a time, until text will fit in width
// or minSize is reached.
export function largestFontSize(doc: jsPDF, maxSize: number, minSize: number, text: string, width: number): number {
  let size: number;
  const origFontSize = doc.getFontSize();
  for (size = maxSize; size >= minSize; size -= 0.5) {
    doc.setFontSize(size);
    const textWidth = doc.getTextWidth(text);
    if (textWidth <= width) break;
  }

  doc.setFontSize(origFontSize);
  return size;
}

export function truncateToWidth(doc: jsPDF, text: string, width: number, minLength = 3): string {
  let truncatedText = text;
  for (; truncatedText.length >= minLength; truncatedText = truncatedText.slice(0, -1)) {
    if (doc.getTextWidth(truncatedText) <= width) break;
  }

  if (truncatedText.length < text.length) {
    if (!truncatedText.length) return "";
    truncatedText = truncatedText.slice(0, -1) + "…";
  }
  return truncatedText;
}
