import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useContext, useState } from "react";
import { Button, Card, Dropdown, DropdownButton, Form, ListGroup, Modal, Spinner } from "react-bootstrap";
import { ArrowDownUp, ExclamationTriangle, List, PlusCircleFill, Trash } from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import { useAbsence } from "../../../api/absence";
import { useCongSettings } from "../../../api/cong";
import { jobApi } from "../../../api/job";
import { meetingsApi, useNotifications } from "../../../api/meetings";
import { publicWitnessingApi } from "../../../api/publicWitnessing";
import QueryKeys from "../../../api/queryKeys";
import { useWMSchedules, weekendApi } from "../../../api/weekend";
import { hasAbsence } from "../../../helpers/absence";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import {
  Month,
  getDayjs,
  isBeforeToday,
  localizedDayOfWeek,
  stringToDate,
  weekOf,
  weekOfString,
} from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import { HGContext } from "../../../helpers/globals";
import useInterval from "../../../helpers/useInterval";
import { nameOfUser } from "../../../helpers/user";
import { NBSP } from "../../../helpers/util";
import { assignmentNotificationQueryKey } from "../../../query/notification";
import { ISODateString, NotificationDates } from "../../../types/date";
import { JobStatus } from "../../../types/job";
import { Absence } from "../../../types/scheduling/absence";
import { Events } from "../../../types/scheduling/events";
import { AssignmentNotification, NotificationType } from "../../../types/scheduling/meetings";
import { PublicWitnessingSchedule } from "../../../types/scheduling/publicWitnessing";
import { CongSettingTypes, CongSettings } from "../../../types/scheduling/settings";
import {
  Congregation,
  PublicTalkAssignment,
  PublicTalkAssignmentInput,
  Speaker,
  SpeakerDirectExchange,
  WMSchedule,
} from "../../../types/scheduling/weekend";
import { ActionsDropdown } from "../../buttons";
import { QueryStatus } from "../../queryStatus";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { AssignmentSummary, useAssignmentMap } from "../RecentAssignments";
import {
  CongName,
  NoOneWithPrivileges,
  congCompare,
  haveAVAAssignment,
  havePWAssignment,
  haveWMCoreAssignment,
  publicTalkCompare,
  sortedWMCongregations,
  speakerCompare,
} from "../common";
import { HourglassDatePicker, MenuType, MonthScheduleMenu } from "../date";
import { EventAlert, EventBadge } from "../eventAlert";
import {
  NotificationIcon,
  NotificationOptions,
  NotificationStatusLegend,
  notificationStatePart,
} from "../notificationStatus";
import {
  CongWeekendMeetingTime,
  DisabledPublicTalkIndicator,
  SpeakerName,
  SpeakerNotOutgoingWarning,
  canUpdateWeekendSchedules,
  outlineDisabled,
  weekendDateRange,
  scheduleDates,
  NumberOfMonthsStorage,
} from "./wm_common";
import { getStoredNumber } from "../../../helpers/dateStored";
import { selectedCong } from "../../../helpers/langGroups";
import DropdownItem from "react-bootstrap/DropdownItem";

export function OutgoingMonthView(props: {
  from: ISODateString;
  to: ISODateString;
  langGroupId: number;
  month: Date;
  setMonth: (d: Date) => void;
  monthCount: number;
  setMonthCount: (c: number) => void;
}) {
  const { t } = useTranslation();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [out, setOut] = useState({} as PublicTalkAssignment);
  const [selectedID, setSelectedID] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [jobId, setJobId] = useState("");
  const queryClient = useQueryClient();

  const hostCong = useContext(HGContext).globals.cong;
  const scheduleQuery = useWMSchedules(props.from, props.to, props.langGroupId);

  // we always want to get our outgoing speakers. they will be local speakers, and so always in the host congregation.
  // that's why we query with language group 0.
  const outSpeakersQuery = useQuery({
    queryKey: [`${QueryKeys.WMOutSpeakers}-local`, props.langGroupId],
    queryFn: () => weekendApi.getSpeakers(hostCong!.id, true, 0),
  });
  const congregationsQuery = useQuery({
    queryKey: [QueryKeys.WMCongregations, props.langGroupId],

    queryFn: () => weekendApi.getCongregations(props.langGroupId),
  });
  const notificationDates: NotificationDates = { start: weekOfString(props.from), end: props.to };
  const notificationsQuery = useNotifications(notificationDates.start, notificationDates.end, NotificationType.PTOut);
  const congSettingsQuery = useCongSettings(props.langGroupId);
  const userAssignmentMap = useAssignmentMap(
    { from: props.from, to: props.to },
    canUpdateWeekendSchedules(),
    props.langGroupId,
  );

  const pwScheduleQuery = useQuery({
    queryKey: [QueryKeys.PublicWitnessingSchedules, props.from, props.to],
    queryFn: () => publicWitnessingApi.getSchedules(props.from, props.to),
  });

  useInterval(async () => {
    if (!jobId) return;
    try {
      const resp = await jobApi.getJob(jobId);
      switch (resp.status) {
        case JobStatus.Pending:
          return;
        case JobStatus.Done:
        case JobStatus.Failed:
          setJobId("");
          setShowSpinner(false);
          break;
      }
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(
          NotificationType.PTOut,
          notificationDates.start,
          notificationDates.end,
        ),
      });
    } catch (err: any) {
      if (getStatusCode(err) === 404) {
        setShowSpinner(false);
        setJobId("");
      } else {
        console.error("wm out: error fetching job status", err);
        HGBugsnagNotify("wmOutgoingJobStatus", err);
      }
    }
  }, 3 * 1000);

  const queryStatus = QueryStatus(scheduleQuery, outSpeakersQuery, congregationsQuery);
  if (queryStatus !== null) return queryStatus;
  if (!outSpeakersQuery.data || !congSettingsQuery.data || !pwScheduleQuery.data) return null;

  const sendAssignments = async () => {
    setShowSpinner(true);
    try {
      const job = await meetingsApi.sendAllNotifications(
        notificationDates.start,
        notificationDates.end,
        NotificationType.PTOut,
        props.langGroupId,
      );
      setJobId(job.id);
      await queryClient.invalidateQueries({
        queryKey: assignmentNotificationQueryKey(
          NotificationType.PTOut,
          notificationDates.start,
          notificationDates.end,
        ),
      });
    } catch (err: any) {
      setShowSpinner(false);
      console.error("wm pt out: error sending notifications", err);
      HGBugsnagNotify("wmOutgoingSendNotifications", err);
    }
  };

  return (
    <div>
      <ConfirmModal
        show={showDeleteConfirm}
        setShow={setShowDeleteConfirm}
        id={selectedID}
        refresh={scheduleQuery.refetch}
        langGroupId={props.langGroupId}
      />
      <MoveModal
        show={showMoveModal}
        setShow={setShowMoveModal}
        out={out}
        refresh={scheduleQuery.refetch}
        langGroupId={props.langGroupId}
      />
      <div className="d-flex justify-content-between">
        <div>
          {canUpdateWeekendSchedules() && !isBeforeToday(props.to) && (
            <ActionsDropdown>
              <Dropdown.Menu>
                <Dropdown.Item onClick={sendAssignments} disabled={showSpinner}>
                  {t("schedules.assignment.send")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </ActionsDropdown>
          )}

          {showSpinner && <Spinner className="ms-2" size="sm" animation="border" />}
        </div>
        <MonthScheduleMenu
          date={props.month}
          setDate={props.setMonth}
          dateSaveKey={CongSettingTypes.WM}
          months={props.monthCount}
          setMonths={props.setMonthCount}
        />
        <div />
      </div>
      <div className="d-flex flex-column pt-3">
        <Weeks
          schedules={scheduleQuery.data ?? []}
          langGroupId={props.langGroupId}
          congregations={sortedWMCongregations(congregationsQuery.data)}
          speakers={outSpeakersQuery.data ?? []}
          setSelectedID={setSelectedID}
          setShowDeleteConfirm={setShowDeleteConfirm}
          refresh={scheduleQuery.refetch}
          notifications={notificationsQuery.data}
          notificationDates={notificationDates}
          setOut={setOut}
          setShowMoveModal={setShowMoveModal}
          congSettings={congSettingsQuery.data}
          userAssignmentMap={userAssignmentMap}
          pwSchedules={pwScheduleQuery.data}
        />
      </div>
      <div className="mt-4 ms-2">
        <NotificationStatusLegend />
      </div>
    </div>
  );
}

const ConfirmModal = (props: {
  show: boolean;
  setShow: (show: boolean) => void;
  id: number;
  refresh: () => void;
  langGroupId: number;
}) => {
  const { t } = useTranslation();
  const handleClose = () => props.setShow(false);
  const handleMove = async () => {
    try {
      await weekendApi.deleteOutSchedule(props.id, props.langGroupId);
      props.refresh();
      handleClose();
    } catch (err: any) {
      console.error("wm out delete error", err);
      HGBugsnagNotify("wmOutgoingDelete", err);
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("popup.confirm.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("schedules.weekend.clear-confirm")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("popup.confirm.button.no")}
        </Button>
        <Button variant="danger" onClick={handleMove}>
          {t("popup.confirm.button.yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const OutSpeakerDDM = (props: {
  speakers: Speaker[];
  out: PublicTalkAssignment;
  meetingDate: ISODateString;
  absences?: Absence[];
  selected?: number;
  setSpeaker: (show: number) => void;
  saving: boolean;
  notifications?: AssignmentNotification[];
  notificationDates: NotificationDates;
  congSettings: CongSettings;
  userAssignmentMap: Map<number, AssignmentSummary[]>;
  outCongregation?: Congregation;
  pwSchedules: PublicWitnessingSchedule[];
}) => {
  const { t } = useTranslation();

  const speaker = props.speakers.find((s) => s.id === props.selected);
  const speakerDisplayName = speaker?.userId ? nameOfUser(speaker) : undefined;
  const hasConflictingAbsence = speaker?.userId
    ? hasAbsence(speaker.userId, stringToDate(props.meetingDate), props.absences)
    : false;

  const notification = props.notifications?.find((n) => speaker?.userId === n.assignee && props.out.id === n.part);
  const notificationState = notificationStatePart(speaker?.userId ?? 0, notification);

  // returns the localized key of the overlapping assignment type, or undefined if there is no overlap
  const hasOverlappingAssignment = (): string | undefined => {
    // see if they have an AVA assignment
    if (!speaker?.userId) return undefined;
    const avaAssignment = haveAVAAssignment(
      speaker.userId,
      props.meetingDate,
      props.congSettings,
      "weekend",
      props.userAssignmentMap,
    );
    if (avaAssignment) {
      return avaAssignment;
    }

    // see if they have any other WM assignment
    if (haveWMCoreAssignment(speaker.userId, props.out.date, props.userAssignmentMap)) return "schedules.weekend.crh";

    if (havePWAssignment(speaker.userId, props.out.date, props.pwSchedules, props.outCongregation)) {
      return "schedules.public-witnessing.title";
    }

    // see if they are also the incoming speaker
    return props.userAssignmentMap
      .get(speaker.userId)
      ?.some((as) => as.type === "publicTalk" && as.date === props.out.date) === true
      ? "schedules.weekend.incoming"
      : undefined;
  };

  const assignmentOverlap = hasOverlappingAssignment();
  const isPastWeek = getDayjs(weekOf(props.out.date)).isBefore(weekOf(new Date()), "day");

  return (
    <Form.Group className="d-flex flex-column">
      <Form.Label className="fw-bolder text-muted mb-1">{t("schedules.weekend.speaker.0")}</Form.Label>
      <DropdownButton
        title={
          <>
            {props.saving ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <span>
                {!isPastWeek && (
                  <NotificationIcon
                    nstate={notificationState}
                    hasAbsence={hasConflictingAbsence}
                    scheduleConflict={!!assignmentOverlap}
                    label={assignmentOverlap}
                  />
                )}
              </span>
            )}
            <span className="ms-1 me-1">{speakerDisplayName || <i>{t("general.none-selected")}</i>}</span>
          </>
        }
        variant="secondary"
        className="dropdown-bounded"
        disabled={!canUpdateWeekendSchedules() || props.saving}
      >
        {!!notification && (
          <>
            <NotificationOptions
              startDate={props.notificationDates.start}
              endDate={props.notificationDates.end}
              nstate={notificationState}
              notification={notification}
              notificationType={NotificationType.PTOut}
            />
          </>
        )}
        {props.speakers.length
          ? props.speakers
              .sort(speakerCompare)
              .filter((s) => s.id !== speaker?.id)
              .map((s) => {
                const isAbsent = s.userId
                  ? hasAbsence(s.userId, stringToDate(props.meetingDate), props.absences)
                  : false;
                return (
                  <Dropdown.Item key={s.id} onClick={() => props.setSpeaker(s.id)} disabled={isAbsent}>
                    <SpeakerName speaker={s} isAbsent={isAbsent} />
                  </Dropdown.Item>
                );
              })
          : [<NoOneWithPrivileges key={0} priv={t("schedules.privileges.public-talks-away")} />]}
      </DropdownButton>
      {!!speaker && <SpeakerNotOutgoingWarning speaker={speaker} />}
    </Form.Group>
  );
};

export const CongDDM = (props: {
  congs: Congregation[];
  selected?: number;
  setCong: (congId: number) => void;
  excludeMyCong?: boolean;
  override?: number;
  langGroupId?: number;
  hideDOW?: boolean;
  hideMeetingTime?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const myCong = useContext(HGContext).globals.cong!;

  type CongOut = {
    id: number;
    name: string;
    disabled: boolean;
  };
  const possibleCongs: CongOut[] = props.congs
    .filter((c) => {
      if (props.excludeMyCong) return props.langGroupId ? c.id !== props.langGroupId : c.id !== myCong.id;
      return true;
    })
    .map((cong) => {
      const dayInfo =
        cong.wm_dow !== myCong.wmdow && !props.hideDOW ? ` [${localizedDayOfWeek(cong.wm_dow, i18n.language)}]` : "";
      return {
        id: cong.id,
        name: CongName(cong) + dayInfo,
        disabled: cong.direct_exchange === SpeakerDirectExchange.Proposed,
      };
    });

  const lastSelectedCong = possibleCongs.find((c) => c.id === props.selected);
  const [selected, setSelected] = useState(lastSelectedCong);
  const selectedCong = props.congs.find((c) => c.id === props.selected);
  const overrideCong = props.congs.find((c) => c.id === props.override);

  const selectCong = (selected: Option[]) => {
    const selectedCongOut = selected[0] as CongOut;
    setSelected(selectedCongOut);
    if (!selectedCongOut) {
      return;
    }
    props.setCong(selectedCongOut.id);
  };

  return (
    <Form.Group>
      <Form.Label className="fw-bolder text-muted mb-1">{t("list.congregation.title")}</Form.Label>
      <Typeahead
        disabled={!canUpdateWeekendSchedules()}
        onFocus={() => setSelected(undefined)}
        onBlur={() => setSelected(lastSelectedCong)}
        selected={overrideCong ? [overrideCong] : selected ? [selected] : []}
        id="out_cong"
        flip
        options={possibleCongs}
        labelKey="name"
        onChange={selectCong}
        maxResults={100}
      />
      {!!selectedCong && props.hideMeetingTime !== true && <CongWeekendMeetingTime cong={selectedCong} />}
    </Form.Group>
  );
};

const TalkDDM = (props: {
  speakers: Speaker[];
  selectedSpeaker?: number;
  selectedTalk?: number;
  setTalk: (show: number) => void;
  date: ISODateString;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const speakerTalks = props.speakers.find((s) => s.id === props.selectedSpeaker)?.public_talks.sort(publicTalkCompare);

  return (
    <Form.Group className="d-flex flex-column">
      <Form.Label className="fw-bolder text-muted mb-1">{t("schedules.general.talk")}</Form.Label>
      <DropdownButton
        variant="secondary"
        className="dropdown-bounded"
        title={props.selectedTalk || " "}
        disabled={!props.selectedSpeaker || !!props.disabled}
      >
        {(speakerTalks?.length ?? 0)
          ? speakerTalks
              ?.filter((pt) => pt.number !== props.selectedTalk)
              .map((pt) => {
                const disabled = outlineDisabled(pt.number, props.date);
                return (
                  <Dropdown.Item key={pt.number} onClick={() => props.setTalk(pt.number)}>
                    {pt.number || NBSP}
                    {!!pt.title && (
                      <div style={{ maxWidth: "30ch" }} className="text-muted text-truncate mb-0">
                        {pt.title}
                      </div>
                    )}
                    {!!disabled && <DisabledPublicTalkIndicator talkDate={disabled} />}
                  </Dropdown.Item>
                );
              })
          : [
              <Dropdown.Item key="none" disabled={true}>
                {t("general.none")}
              </Dropdown.Item>,
            ]}
      </DropdownButton>
    </Form.Group>
  );
};

const AssignmentUI = (props: {
  out: PublicTalkAssignment;
  langGroupId: number;
  setOut: (set: PublicTalkAssignment) => void;
  speakers: Speaker[];
  congregations: Congregation[];
  setSelectedID: (set: number) => void;
  setShowDeleteConfirm: (set: boolean) => void;
  notifications?: AssignmentNotification[];
  notificationDates: NotificationDates;
  setShowMoveModal: (set: boolean) => void;
  congSettings: CongSettings;
  userAssignmentMap: Map<number, AssignmentSummary[]>;
  pwSchedules: PublicWitnessingSchedule[];
}) => {
  const { t } = useTranslation();
  const [speaker, setSpeaker] = useState(props.out.speaker?.id ?? 0);
  const [talk, setTalk] = useState(props.out.public_talk?.number ?? 0);
  const [congId, setCongId] = useState(props.out.congregation?.id ?? 0);
  const [saveResult, setSaveResult] = useState(SaveResult.None);
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();
  const absenceQuery = useAbsence();

  const meetingDate = weekOfString(props.out.date, props.congregations.find((c) => c.id === congId)?.wm_dow ?? 7);

  const save = async (speaker: number, cong: number, talk: number, force?: boolean) => {
    // force lets the cong save happen regardless of whether anything else is specified
    if (!force && (!talk || !cong || !speaker)) return;
    setSaving(true);
    try {
      await weekendApi.setOutSchedule(
        {
          id: props.out.id,
          date: props.out.date,
          assignee: speaker,
          congregation: cong,
          talk: talk,
        },
        props.langGroupId,
      );
      setSaveResult(SaveResult.Success);
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.WMCSchedules],
      });
    } catch (err: any) {
      setSaveResult(SaveResult.Failure);
      console.error("wm outgoing save error", err);
      HGBugsnagNotify("wmOutgoingSave", err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <ListGroup.Item className="d-flex align-items-start" key={props.out.id}>
      <div className="flex-grow-1 row p-2">
        <div className="col-12 col-lg-5 mb-2">
          <OutSpeakerDDM
            speakers={props.speakers}
            absences={absenceQuery.data}
            out={props.out}
            meetingDate={meetingDate}
            selected={speaker}
            saving={saving}
            notifications={props.notifications}
            notificationDates={props.notificationDates}
            congSettings={props.congSettings}
            userAssignmentMap={props.userAssignmentMap}
            outCongregation={props.congregations.find((c) => c.id === congId)}
            pwSchedules={props.pwSchedules}
            setSpeaker={(speaker) => {
              //when we change the speaker, we still need to know what talk, so we don't save it
              setSpeaker(speaker);
              setTalk(0);
            }}
          />
        </div>
        <div className="col-12 col-lg-5">
          <CongDDM
            congs={props.congregations.sort(congCompare)}
            excludeMyCong
            langGroupId={props.langGroupId}
            selected={congId}
            setCong={async (cong) => {
              setCongId(cong);
              if (cong) {
                // as long as we have a cong selected, save it
                await save(speaker, cong, talk, true);
              }
            }}
          />
        </div>
        <div className="col-12 col-lg-2">
          <TalkDDM
            speakers={props.speakers}
            disabled={!canUpdateWeekendSchedules()}
            selectedSpeaker={speaker}
            selectedTalk={talk}
            setTalk={async (talk) => {
              setTalk(talk);
              await save(speaker, congId, talk);
            }}
            date={meetingDate}
          />
        </div>
      </div>
      <Dropdown className="mt-4">
        <Dropdown.Toggle variant="secondary" className="mt-2">
          {saveResult === SaveResult.None ? (
            <List />
          ) : (
            <SaveStatus
              noSpace
              size={13.5}
              saveResult={saveResult}
              saveKey={props.out.id}
              setSaveResult={setSaveResult}
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            disabled={!canUpdateWeekendSchedules()}
            onClick={() => {
              props.setOut(props.out);
              props.setShowMoveModal(true);
            }}
          >
            <ArrowDownUp /> {t("schedules.weekend.move-assignment")}
          </Dropdown.Item>
          <Dropdown.Item
            disabled={!canUpdateWeekendSchedules()}
            onClick={() => {
              props.setSelectedID(props.out.id);
              props.setShowDeleteConfirm(true);
            }}
          >
            <Trash color="red" /> {t("schedules.weekend.delete-outgoing")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </ListGroup.Item>
  );
};

const Weeks = (props: {
  schedules: WMSchedule[];
  langGroupId: number;
  congregations: Congregation[];
  speakers: Speaker[];
  setSelectedID: (set: number) => void;
  setShowDeleteConfirm: (set: boolean) => void;
  refresh: () => void;
  notifications?: AssignmentNotification[];
  notificationDates: NotificationDates;
  setOut: (set: PublicTalkAssignment) => void;
  setShowMoveModal: (set: boolean) => void;
  congSettings: CongSettings;
  userAssignmentMap: Map<number, AssignmentSummary[]>;
  pwSchedules: PublicWitnessingSchedule[];
}) => {
  const { t, i18n } = useTranslation();
  const myCong = useContext(HGContext).globals.cong!;

  const outs = props.schedules.map((week) => {
    // do we have a notification for something that won't be shown here? (e.g. a deleted or moved talk)
    const notificationMismatch = (): boolean => {
      let found = false;
      props.notifications
        ?.filter((n) => n.date === week.date)
        .forEach((n) => {
          if (n.type === NotificationType.PTOut && !week.out.some((w) => w.id === n.part)) found = true;
        });
      return found;
    };

    const assignments = week.out.map((out) => {
      return (
        <AssignmentUI
          out={out}
          langGroupId={props.langGroupId}
          setOut={props.setOut}
          setShowMoveModal={props.setShowMoveModal}
          key={out.id}
          speakers={props.speakers}
          congregations={props.congregations}
          setSelectedID={props.setSelectedID}
          setShowDeleteConfirm={props.setShowDeleteConfirm}
          notifications={props.notifications}
          notificationDates={props.notificationDates}
          congSettings={props.congSettings}
          userAssignmentMap={props.userAssignmentMap}
          pwSchedules={props.pwSchedules}
        />
      );
    });

    const weekendDates = weekendDateRange(week.date);

    return (
      <Card className="mb-4" key={week.date}>
        <Card.Body className="d-flex flex-row gap-4">
          {weekendDates[0] && weekendDates[1] && (
            <div className="d-flex flex-column align-content-center">
              <Card className="calendar-double-day">
                {(weekendDates[0]?.getMonth() !== weekendDates[1]?.getMonth() && (
                  <Card.Header className="bg-primary text-white text-center text-uppercase fw-bold py-1 px-0 mx-0 row">
                    <span className={"col-6 px-3 text-nowrap" + ((myCong.wmdow === 7 && " text-muted") || "")}>
                      {Month.fromDate(weekendDates[0]).toLocaleMonthName(i18n.language).slice(0, 3)}
                    </span>
                    <span
                      className={"col-6 px-3 text-nowrap border-start" + ((myCong.wmdow === 6 && " text-muted") || "")}
                    >
                      {Month.fromDate(weekendDates[1]).toLocaleMonthName(i18n.language).slice(0, 3)}
                    </span>
                  </Card.Header>
                )) || (
                  <Card.Header className="bg-primary text-white text-center text-uppercase fw-bold py-1">
                    {Month.fromDate(weekendDates[0]).toLocaleMonthName(i18n.language)}
                  </Card.Header>
                )}
                <Card.Body className="py-1 px-0 text-center mx-0 row">
                  <div className={"col-6 px-3 fs-1 text-nowrap" + ((myCong.wmdow === 7 && " text-muted") || "")}>
                    {weekendDates[0].getDate()}
                  </div>
                  <div
                    className={
                      "col-6 px-3 fs-1 border-start text-nowrap" + ((myCong.wmdow === 6 && " text-muted") || "")
                    }
                  >
                    {weekendDates[1].getDate()}
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}

          <div className="d-flex flex-wrap align-self-start flex-grow-1">
            <EventBadge context="wm" event={week.event} />
            {notificationMismatch() && <ExclamationTriangle className="ms-2 mt-1" />}

            <div className="col-12">
              {week.out.length === 0 ? (
                <>
                  {week.event?.event === Events.mem && <EventAlert context="wm" event={week.event} />}
                  <p className="mb-3">{t("schedules.weekend.no-outgoing-talks")}</p>
                </>
              ) : (
                <ListGroup className="mb-3">{assignments}</ListGroup>
              )}
            </div>

            <Button
              variant="outline-primary"
              disabled={!canUpdateWeekendSchedules()}
              className="py-2 d-flex align-items-center"
              title={t("schedules.weekend.add-outgoing")}
              onClick={async () => {
                await weekendApi.setOutSchedule({ date: week.date } as PublicTalkAssignmentInput, props.langGroupId);
                props.refresh();
              }}
            >
              <PlusCircleFill className="me-1" size={18} />
              <span>{t("nav.publishers.add")}</span>
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  });

  return <React.Fragment>{outs}</React.Fragment>;
};

function MoveModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  out: PublicTalkAssignment;
  refresh: () => void;
  langGroupId: number;
}) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState("");
  const [toSwap, setToSwap] = useState<PublicTalkAssignment>();
  const ctx = useContext(HGContext);
  const myCong = selectedCong(props.langGroupId) ?? ctx.globals.cong!;
  const dates = scheduleDates(
    new Date(selectedDate || props.out.date),
    getStoredNumber(NumberOfMonthsStorage) ?? 1,
    myCong.wmdow,
  );
  const scheduleQuery = useWMSchedules(dates.from, dates.to, props.langGroupId);

  const handleClose = () => {
    props.setShow(false);
    setToSwap(undefined);
    setSelectedDate("");
  };

  const handleMove = async () => {
    try {
      await weekendApi.setOutSchedule(
        {
          id: props.out.id,
          date: selectedDate,
          assignee: props.out.speaker?.id ?? 0,
          congregation: props.out.congregation?.id ?? 0,
          talk: props.out.public_talk?.number ?? 0,
        },
        props.langGroupId,
      );
      props.refresh();
      handleClose();
    } catch (err: any) {
      console.error("wm out move error", err);
      HGBugsnagNotify("wmOutgoingMove", err);
    }
  };

  const handleSwap = async () => {
    if (!toSwap) return;
    try {
      await weekendApi.setOutSchedule(
        {
          id: props.out.id,
          date: selectedDate,
          assignee: props.out.speaker?.id ?? 0,
          congregation: props.out.congregation?.id ?? 0,
          talk: props.out.public_talk?.number ?? 0,
        },
        props.langGroupId,
      );
      await weekendApi.setOutSchedule(
        {
          id: toSwap.id,
          date: props.out.date,
          assignee: toSwap.speaker?.id ?? 0,
          congregation: toSwap.congregation?.id ?? 0,
          talk: toSwap.public_talk?.number ?? 0,
        },
        props.langGroupId,
      );

      props.refresh();
      handleClose();
    } catch (err: any) {
      console.error("wm out swap error", err);
      HGBugsnagNotify("wmOutgoingSwap", err);
    }
  };

  const selectedWeek = weekOfString(selectedDate);
  const outgoingOnSelectedDate =
    scheduleQuery.data
      ?.filter((s) => weekOfString(s.date) === selectedWeek)
      ?.flatMap((s) => s.out)
      ?.filter((pt) => !!pt.speaker) ?? [];

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.talk-mover")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <HourglassDatePicker
          menuType={MenuType.Day}
          date={selectedDate || weekOfString(props.out.date, props.out.congregation?.wm_dow ?? 7)}
          onDateChange={setSelectedDate}
          required
        />

        {outgoingOnSelectedDate.length > 0 && (
          <div className="mt-4">
            <p>{t("schedules.weekend.outgoing-swap-prompt")}</p>
            <DropdownButton
              variant="secondary"
              className="dropdown-bounded"
              title={toSwap?.speaker ? nameOfUser(toSwap.speaker) : ""}
            >
              {outgoingOnSelectedDate.map((pt) => (
                <DropdownItem key={pt.id} onClick={() => setToSwap(pt)}>
                  {!!pt.speaker && <SpeakerName speaker={pt.speaker} />}
                </DropdownItem>
              ))}
            </DropdownButton>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
        {outgoingOnSelectedDate.length > 0 && !!toSwap && (
          <Button variant="danger" onClick={handleSwap}>
            {t("schedules.weekend.swap")}
          </Button>
        )}
        <Button variant="danger" onClick={handleMove}>
          {t("schedules.weekend.move-assignment")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
