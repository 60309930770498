import { ISODateString } from "../date";
import { CongSettings } from "./settings";
import { Tag } from "./tags";

export interface UserTerritory extends TerritoryRecord {
  territory: Territory;
}

export interface TerritoryRecord {
  id: number;
  congregation_id: number;
  territory_id: number;
  user_id: number;
  start: ISODateString | null; // not really ever null except when we generate pseudo-records
  end: ISODateString | null;
  notes?: string;
  tags: Tag[];
}

export interface TerritoryRequest {
  id: number;
  userId: number;
  requested: ISODateString;
  notes: string;
}

export type Territory = {
  id: number;
  number: string;
  locality: string;
  business: boolean;
  url?: string;
  boundaries?: string;
  annotations?: string;
  notes?: string;
  addresses?: TerritoryAddress[];
  disabled: boolean;
  tags: Tag[];
  children?: Territory[];
  parentId?: number;
};
export const defaultSortOrder = 9999;
export type TerritoryAddress = {
  id: number;
  territoryId: number;
  sortOrder: number;
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  postalcode?: string;
  notes?: string;
  name?: string;
  phone?: string;
  lastWorked?: ISODateString;
  lastWorkedBy?: number;
  dnc: boolean;
  lang?: string;
  status?: TerritoryAddressStatus;
  locationType?: TerritoryAddressLocationType;
  location?: MapPoint;
  hideOnMap: boolean;
  pinLabel?: string;
  tags: Tag[];
};

export type TerritoryAddressCSV = TerritoryAddress & { latitude?: string; longitude?: string };

export type TerritoryMoveAddresses = {
  addressIds: number[];
  newTerritoryId: number;
};

export type TerritoryAddressSortOrder = Map<number, number>;

export enum TerritoryAddressLocationType {
  Business = "business",
  Residence = "residence",
  Custom = "custom",
}

export enum TerritoryAddressStatus {
  NotHome = "nh",
  ReturnVisit = "rv",
  NotInterested = "ni",
  Worked = "worked",
}

export type MapPoint = {
  x: number;
  y: number;
  srid: number;
};

export interface TerritoryThings {
  settings: CongSettings;
  businessOnly: boolean;
  setBusinessOnly: (set: boolean) => void;
}

export type TerritoryCong = {
  boundaries: string;
  notes?: string;
};

export type TerritoryImport = {
  territories: Territory[];
  records: TerritoryRecord[];
};

export enum HGLayerType {
  Boundary,
  Annotation,
}
