import { Suspense, useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  useNavigate,
  useRouteError,
} from "react-router";
import { ErrorFallback } from "../errorFallback";
import { HGBugsnagNotify } from "../helpers/bugsnag";
import HourglassGlobals from "../helpers/globals";
import { HGNavigate } from "../helpers/navigate";
import {
  ActivityLazy,
  CongProfileLazy,
  E2EMigrationLazy,
  HourglassImportLazy,
  ManageFSGroupsLazy,
  ManageLangGroupsLazy,
  MeetingAttendanceLazy,
  TerritoryLazy,
} from "./congregation/async";
import CongregationSettings from "./congregation/settings/settings";
import {
  DocE2ELazy,
  DocFAQLazy,
  DocFSGroupsLazy,
  DocImportingLazy,
  DocIntroLazy,
  DocMobileAppsLazy,
  DocMonthlySummaryLazy,
  DocMonthlyWorkflowLazy,
  DocPrintingLazy,
  DocPubTransferLazy,
  DocSchedulingLazy,
  DocTerritoriesLazy,
  DocTopicsLazy,
} from "./docs/async";
import HourglassMain from "./main";
import MainContent from "./main-content-wrap";
import { NavCongregation } from "./nav/congregation";
import MainNav, { MobileNav } from "./nav/main-nav";
import NewNav from "./nav/new-nav";
import { NavPublishers } from "./nav/publishers";
import { NavReports } from "./nav/reports";
import { NavScheduling } from "./nav/scheduling";
import {
  ContactListLazy,
  EmergencyContactsListLazy,
  ManageDelegatesLazy,
  ManageEmergencyContactsLazy,
  ManagePublisherLazy,
  ManageUserAuthLazy,
  NotPublishingLazy,
  PubTransferLazy,
  PublisherImportLazy,
  PublisherMainLazy,
  UserTagsLazy,
} from "./publishers/async";
import {
  AuxPioReportLazy,
  COReportsLazy,
  CongregationAnalysisLazy,
  CongregationAveragesLazy,
  GroupAveragesLazy,
  MissingReportLazy,
  MonthDetailLazy,
  MonthStatisticsLazy,
  MonthSummaryLazy,
  PublisherAveragesLazy,
  RegPioReportLazy,
  ServiceYearTotalsLazy,
} from "./reports/async";
import {
  AVAttendantLazy,
  CircuitLazy,
  CleaningScheduleLazy,
  CustomAssignmentsLazy,
  FSMeetingsLazy,
  MaintenanceLazy,
  MemorialLazy,
  MidweekMeetingLazy,
  PrivilegeMatrixLazy,
  PrivilegesLazy,
  PublicWitnessingLazy,
  SchedulingAbsencesLazy,
  SchedulingEventsLazy,
  SchedulingSettingsLazy,
  WeekendMeetingLazy,
} from "./scheduling/async";
import { SuspenseFallback } from "./utils";

function SetNavigateInstance() {
  HGNavigate.navigateInstance = useNavigate();
  return null;
}

export function RouterErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    console.error("router error", error);
    HGBugsnagNotify("routerError", error);
  });

  return <ErrorFallback clearError={() => navigate("/")} />;
}

//<BrowserRouter basename={HourglassGlobals.AppBase}>
const router = createBrowserRouter(
  [
    {
      path: "*",
      Component: AuthRoutes,
      errorElement: <RouterErrorBoundary />,
    },
  ],
  {
    basename: HourglassGlobals.AppBase,
  },
);

export function AuthRouter() {
  return <RouterProvider router={router} />;
}

function AuthLayout() {
  return (
    <>
      <SetNavigateInstance />
      <div className="app-wrapper">
        <div className="menu-wrapper d-none d-md-block">
          <MainNav />
        </div>
        <div className="main-wrapper">
          <NewNav />
          <div>
            <MainContent>
              <Suspense fallback={<SuspenseFallback />}>
                <Outlet />
              </Suspense>
            </MainContent>
          </div>
          <MobileNav />
        </div>
      </div>
    </>
  );
}

function AuthRoutes() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/user/privileges/:id" element={<PrivilegesLazy />} />
        <Route path="/user/auth/:id" element={<ManageUserAuthLazy />} />
        <Route path="/user/edit/:id" element={<ManagePublisherLazy />} />
        {/*to force a re-render if someone is editing one publisher and adds another*/}
        <Route path="/user/edit/0" element={<ManagePublisherLazy key={0} />} />
        <Route path="/user/ec/:id" element={<ManageEmergencyContactsLazy />} />
        <Route path="/user/delegates/:id" element={<ManageDelegatesLazy />} />
        <Route path="/user/:id" element={<PublisherMainLazy />} />
        <Route path="/transfer" element={<PubTransferLazy />} />
        <Route path="/publisher/import" element={<PublisherImportLazy />} />
        <Route path="/publisher/tags" element={<UserTagsLazy />} />
        <Route path="/notPublishing" element={<NotPublishingLazy />} />
        <Route path="/contactList" element={<ContactListLazy />} />
        <Route path="/emergencylist" element={<EmergencyContactsListLazy />} />
        <Route path="/missing" element={<MissingReportLazy />} />
        <Route path="/monthSummary/:year/:month" element={<MonthSummaryLazy />} />
        <Route path="/monthSummary" element={<MonthSummaryLazy />} />
        <Route path="/monthDetail/:year/:month" element={<MonthDetailLazy />} />
        <Route path="/monthDetail" element={<MonthDetailLazy />} />
        <Route path="/monthStatistics/:year/:month" element={<MonthStatisticsLazy />} />
        <Route path="/monthStatistics" element={<MonthStatisticsLazy />} />
        <Route path="/congAvg/:startYear/:startMonth/:endYear/:endMonth" element={<CongregationAveragesLazy />} />
        <Route path="/congAvg" element={<CongregationAveragesLazy />} />
        <Route path="/groupAvg/:groupId/:startYear/:startMonth/:endYear/:endMonth" element={<GroupAveragesLazy />} />
        <Route path="/groupAvg" element={<GroupAveragesLazy />} />
        <Route path="/rpSummary" element={<RegPioReportLazy />} />
        <Route path="/apSummary" element={<AuxPioReportLazy />} />
        <Route path="/svcYearTotals" element={<ServiceYearTotalsLazy />} />
        <Route path="/coReports" element={<COReportsLazy />} />
        <Route path="/pubAvg" element={<PublisherAveragesLazy />} />
        <Route path="/congAnalysis" element={<CongregationAnalysisLazy />} />
        <Route path="/scheduling/events" element={<SchedulingEventsLazy />} />
        <Route path="/scheduling/absence" element={<SchedulingAbsencesLazy />} />
        <Route path="/scheduling/cleaning" element={<CleaningScheduleLazy />} />
        <Route path="/scheduling/fsMeeting" element={<FSMeetingsLazy />} />
        <Route path="/scheduling/publicWitnessing" element={<PublicWitnessingLazy />} />
        <Route path="/scheduling/memorial" element={<MemorialLazy />} />
        <Route path="/scheduling/custom" element={<CustomAssignmentsLazy />} />
        <Route path="/scheduling/maintenance" element={<MaintenanceLazy />} />
        <Route path="/scheduling/avattendant" element={<AVAttendantLazy />} />
        <Route path="/scheduling/privileges" element={<PrivilegesLazy />} />
        <Route path="/scheduling/privilege_matrix" element={<PrivilegeMatrixLazy />} />
        <Route path="/scheduling/settings" element={<SchedulingSettingsLazy />} />
        <Route path="/scheduling/wm" element={<WeekendMeetingLazy />} />
        <Route path="/congregation/territory" element={<TerritoryLazy />} />
        <Route path="/activity" element={<ActivityLazy />} />
        <Route path="/scheduling/mm" element={<MidweekMeetingLazy />} />
        <Route path="/manageGroups" element={<ManageFSGroupsLazy />} />
        <Route path="/manageLangGroups" element={<ManageLangGroupsLazy />} />
        <Route path="/attendance" element={<MeetingAttendanceLazy />} />
        <Route path="/congProfile" element={<CongProfileLazy />} />
        <Route path="/import" element={<HourglassImportLazy />} />
        <Route path="/e2eMigration" element={<E2EMigrationLazy />} />
        <Route path="/settings" element={<CongregationSettings />} />
        <Route path="/nav/publishers" element={<NavPublishers />} />
        <Route path="/nav/reports" element={<NavReports />} />
        <Route path="/nav/scheduling" element={<NavScheduling />} />
        <Route path="/nav/congregation" element={<NavCongregation />} />
        <Route path="/docs/intro" element={<DocIntroLazy />} />
        <Route path="/docs/faq" element={<DocFAQLazy />} />
        <Route path="/docs/monthlyworkflow" element={<DocMonthlyWorkflowLazy />} />
        <Route path="/docs/monthsummary" element={<DocMonthlySummaryLazy />} />
        <Route path="/docs/fsgroups" element={<DocFSGroupsLazy />} />
        <Route path="/docs/importing" element={<DocImportingLazy />} />
        <Route path="/docs/mobileapps" element={<DocMobileAppsLazy />} />
        <Route path="/docs/pubtransfer" element={<DocPubTransferLazy />} />
        <Route path="/docs/e2e" element={<DocE2ELazy />} />
        <Route path="/docs/scheduling" element={<DocSchedulingLazy />} />
        <Route path="/docs/territories" element={<DocTerritoriesLazy />} />
        <Route path="/docs/printing" element={<DocPrintingLazy />} />
        <Route path="/docs" element={<DocTopicsLazy />} />
        <Route path="/circuit" element={<CircuitLazy />} />
        <Route path="/" element={<HourglassMain />} />
        {/* fallback in case someone comes with an invalid path: */}
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
