import { Driver, driver, DriveStep } from "driver.js";
import "driver.js/dist/driver.css";
import "../css/driver-tour.css";
import { useEffect } from "react";
import { t } from "../helpers/locale";
import { HGNavigate } from "../helpers/navigate";

// Store tour state outside component to persist across remounts
let tourHasLaunched = false;

export function HourglassTour(props: { userId: number }) {
  // Initialize driver once and handle cleanup properly
  useEffect(() => {
    // Early return if tour already launched or shouldn't be shown
    if (tourHasLaunched) {
      return;
    }

    // Mark as launched immediately to prevent duplicate initialization
    tourHasLaunched = true;
    const isDarkMode = document.documentElement.getAttribute("data-bs-theme") === "dark";

    // Create a fresh driver instance
    const driverInstance = driver({
      animate: true,
      showProgress: true,
      showButtons: ["next", "previous", "close"],
      nextBtnText: t("tour.button.next"),
      prevBtnText: t("general.go-back"),
      doneBtnText: t("tour.button.exit"),
      allowClose: true,
      //overlayClickNext: false,
      popoverClass: isDarkMode ? "driver-dark-theme" : "",
      onHighlightStarted: (element) => {
        if (element && element.id) {
          if (["publishers-nav", "reports-nav", "scheduling-nav", "cong-nav"].includes(element.id)) {
            const button = document.querySelector(`#${element.id} button`) as HTMLButtonElement;
            button?.click();
          }
        }
      },
    });

    // Define steps and start tour
    const steps = getTourSteps(props.userId, driverInstance);
    driverInstance.setSteps(steps);
    // Use a small timeout to ensure DOM is ready
    setTimeout(() => {
      driverInstance.drive();
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function pubDetailsText(): string {
  return `<p>${t("tour.pubdetails.intro")}</p>
    <p>${t("tour.pubdetails.invitations")} <b class="ms-1 me-1">  ${t("tour.pubdetails.invitations-security")}</b> ${t(
      "tour.pubdetails.invitations-multiple",
    )} </p>`;
}

function pubMenuText(): string {
  return `<ul>
    <li><b>${t("nav.publishers.add")}</b> ${t("tour.pubmenu.add")}</li>
    <li><b>${t("nav.publishers.send-reminders")}</b> ${t("tour.pubmenu.send-reminders")}</li>
    <li><b>${t("nav.publishers.record-cards")}</b> ${t("tour.pubmenu.record-cards")}</li>
    <li><b>${t("nav.publishers.not-publishing")}</b> ${t("tour.pubmenu.not-publishing")}</li>
    <li><b>${t("nav.publishers.transfer")}</b> ${t("docs.pubtransfer.1")}</li>
    <li><b>${t("nav.publishers.contacts.all-publishers")}</b> ${t("tour.pubmenu.all-publishers")}</li>
    <li><b>${t("nav.publishers.contacts.emergency")}</b> ${t("tour.pubmenu.emergency")}</li>
    </ul>`;
}

function reportsMenuText(): string {
  return `<ul>
  <li><b>${t("chart.legend.missing")}</b> ${t("tour.reportsmenu.missing")}</li>
  <li><b>${t("nav.reports.monthly.summary")}</b> ${t("tour.reportsmenu.monthly.summary")}</li>
  <li><b>${t("nav.reports.monthly.detail")}</b> ${t("tour.reportsmenu.monthly.detail")}</li>
  <li><b>${t("list.congregation.title")}</b> ${t("tour.reportsmenu.averages.congregation")}</li>
  <li><b>${t("nav.reports.averages.group")}</b> ${t("tour.reportsmenu.averages.group")}</li>
  <li><b>${t("list.publishers.title")}</b> ${t("tour.reportsmenu.averages.publisher")}</li>
  <li><b>${t("recordcard.svcyeartotals.regular-pioneers")}</b> ${t("tour.reportsmenu.pioneers")}</li>
  <li><b>${t("nav.reports.cong-analysis")}</b> ${t("tour.reportsmenu.cong-analysis")}</li>
  <li><b>${t("nav.reports.svcyeartotals")}</b> ${t("tour.reportsmenu.svcyeartotals")}</li>
</ul>`;
}

function schedulingMenuText(): string {
  return `<ul>
  <li><b>${t("conganalysis.attendance.midweek")}</b> ${t("www.features.scheduling.midweek")}</li>
  <li>
    <b>${t("conganalysis.attendance.weekend")}</b>
    ${t("www.features.scheduling.weekend")} <br/>
    ${t("www.features.scheduling.public-talks")}
  </li>
  <li><b>${t("schedules.ava.title")}</b> ${t("www.features.scheduling.ava")}</li>
  <li><b>${t("schedules.cleaning.title")}</b>/<b>${t("schedules.field-service.title")}</b> ${t(
    "www.features.scheduling.fs-cleaning",
  )}</li>
  <li><b>${t("schedules.public-witnessing.title")}</b> ${t("www.features.scheduling.public-witnessing")}</li>
  <li><b>${t("schedules.privileges.title")}</b> ${t("www.features.scheduling.privileges")}</li>
  <li><b>${t("schedules.absence.title")}</b> ${t("www.features.scheduling.absences")}</li>
  <li><b>${t("schedules.events.title")}</b>
    <ul>
      <li>${t("schedules.events.co-visit")}</li>
      <li>${t("schedules.events.circuit-assembly")}</li>
      <li>${t("schedules.events.regional-convention")}</li>
      <li>${t("schedules.events.memorial")}</li>
    </ul>
  </li>
</ul>`;
}

function congMenuText(): string {
  return `<ul>
  <li><b>${t("nav.congregation.fsgroups")}</b> ${t("tour.congmenu.fsgroups")}</li>
  <li><b>${t("nav.congregation.attendance")}</b> ${t("tour.congmenu.attendance")}</li>
  <li><b>${t("nav.congregation.profile-permissions")}</b> ${t("tour.congmenu.profile-permissions")}</li>
  <li><b>${t("nav.congregation.import")}</b> ${t("tour.congmenu.import")}</li>
  <li><b>${t("nav.congregation.export")}</b> ${t("tour.congmenu.export")}</li>
</ul>`;
}

export function getTourSteps(userId: number, driverInstance: Driver): DriveStep[] {
  const youtubeIntroIFrame =
    '<iframe width="560" height="315" src="https://www.youtube.com/embed/WAbQKpN7zrM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

  return [
    {
      popover: {
        title: t("tour.title"),
        description: t("tour.welcome"),

        align: "center",
      },
    },
    {
      element: "#user-search",
      popover: {
        title: t("list.publishers.title"),
        description: "<p>" + t("tour.userlist.main") + "</p>",
        side: "bottom",
        align: "center",
        onNextClick: () => {
          HGNavigate.navigateInstance && HGNavigate.navigateInstance(`/user/${userId}`);
          return new Promise((resolve) => {
            setTimeout(() => {
              driverInstance.moveNext();
              resolve(0);
            }, 500);
          });
        },
      },
    },
    {
      element: "#reports-list-table",
      popover: {
        title: t("nav.reports.0"),
        description: t("tour.reports.main"),
        side: "over",
        align: "center",
      },
    },
    {
      element: "#publisher-summary-card",
      popover: {
        title: t("tour.pubdetails.title"),
        description: pubDetailsText(),
        side: "bottom",
        align: "center",
      },
    },
    {
      element: "#publishers-nav",
      popover: {
        title: t("list.publishers.title"),
        description: pubMenuText(),
        side: "right",
        align: "start",
      },
    },
    {
      element: "#reports-nav",
      popover: {
        title: t("nav.reports.0"),
        description: reportsMenuText(),
        side: "right",
        align: "start",
      },
    },
    {
      element: "#scheduling-nav",
      popover: {
        title: t("schedules.scheduling"),
        description: schedulingMenuText(),
        side: "right",
        align: "start",
      },
    },
    {
      element: "#cong-nav",
      popover: {
        title: t("list.congregation.title"),
        description: congMenuText(),
        side: "right",
        align: "start",
      },
    },
    {
      popover: {
        title: t("tour.title"),
        description: `<p class="mb-4">${t("tour.finished")}</p>` + youtubeIntroIFrame,
        align: "center",
        popoverClass: "tour-end",
      },
    },
  ];
}
