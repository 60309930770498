import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GeoJsonObject, MultiPolygon, Position } from "geojson";
import { t } from "i18next";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, OverlayTrigger, Popover, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import {
  Building,
  Download,
  FileEarmarkSpreadsheet,
  House,
  PencilSquare,
  PinMap,
  PinMapFill,
  Slash,
  XCircleFill,
} from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import Accordion from "react-bootstrap/Accordion";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MdDragHandle } from "react-icons/md";
import { TbCurrentLocation } from "react-icons/tb";
import Select from "react-select";
import urljoin from "url-join";
import QueryKeys from "../../../api/queryKeys";
import { tagsAPI, useTags } from "../../../api/tags";
import { territoryApi, useTerritories } from "../../../api/territory";
import worldLocales from "../../../fixtures/locales.json";
import States from "../../../fixtures/states.json";
import { addressToString, territoryAddressCompare } from "../../../helpers/address";
import { HGBugsnagNotify } from "../../../helpers/bugsnag";
import { ISODateFormat, dateToString, getDayjs, localizedLanguageName } from "../../../helpers/dateHelpers";
import { getStatusCode } from "../../../helpers/errors";
import { isValidLatLng, latLngToMapPoint, mapPointFromPosition, positionFromMapPoint } from "../../../helpers/geo";
import { HGContext } from "../../../helpers/globals";
import { CollatorSingleton, containsFuzzy } from "../../../helpers/locale";
import { Taggable } from "../../../helpers/tags";
import { arrayMove } from "../../../helpers/util";
import { readXLSX, saveXLSX, asOptionalString } from "../../../helpers/xlsx";
import { deleteFromTerritoryAddressCache, updateTerritoryAddressCache } from "../../../query/territory";
import { CountryState } from "../../../types/cong";
import { CongSettings } from "../../../types/scheduling/settings";
import { PseudoTag, Tag, TagCategory, TagLinkUnlink } from "../../../types/scheduling/tags";
import {
  MapPoint,
  Territory,
  TerritoryAddress,
  TerritoryAddressLocationType,
  TerritoryAddressStatus,
  TerritoryImport,
  TerritoryMoveAddresses,
  defaultSortOrder,
} from "../../../types/scheduling/territory";
import { DeleteButton } from "../../buttons";
import { SaveResult, SaveStatus } from "../../saveStatus";
import { HourglassDatePicker, MenuType } from "../../scheduling/date";
import { TagLinkForm, TagList, TagsDDM, filterTags, handleSaveLinkUnlink, tagName } from "../tags";
import { TerritoryOption, terrLabel, territoriesWithChildren, territoryCompare } from "./common";
import { MapWithGeoJson, addressIconUrl } from "./maps";
import { BsTranslate } from "react-icons/bs";
import pointInPolygon from "point-in-polygon";

export function TerritoryAddressModal(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  territory: Territory;
  address?: TerritoryAddress;
  congSettings: CongSettings;
  setSingleAddressMove: (addressId: number) => void;
  territoryAddresses: TerritoryAddress[];
}) {
  const { t } = useTranslation();
  const [key, setKey] = useState("single");

  useEffect(() => {
    // setting it to single right away leads to a flicker back to the single tab.
    if (!props.show) setTimeout(() => setKey("single"), 200);
  }, [props.show]);
  const handleClose = () => props.setShow(false);

  return (
    <Modal show={props.show} onHide={handleClose} size="lg">
      <Tabs activeKey={key} onSelect={(k) => setKey(k as string)} className="mb-3">
        <Tab eventKey="single" title={t("schedules.weekend.tab-speaker-single")}>
          <TerritoryAddressDetail
            territory={props.territory}
            address={props.address}
            congSettings={props.congSettings}
            setShow={props.setShow}
            setSingleAddressMove={props.setSingleAddressMove}
            territoryAddresses={props.territoryAddresses}
          />
        </Tab>
        <Tab eventKey="multi" title={t("schedules.weekend.tab-speaker-multiple")}>
          <AddMultiTerritoryAddresses territory={props.territory} setShow={props.setShow} />
        </Tab>
      </Tabs>
    </Modal>
  );
}

export function TerritoryAddressDetail(props: {
  territory: Territory;
  address?: TerritoryAddress;
  territoryAddresses: TerritoryAddress[];
  congSettings: CongSettings;
  setShow: (show: boolean) => void;
  setSingleAddressMove: (addressId: number) => void;
  expandAddress?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const ctx = useContext(HGContext);
  const cong = ctx.globals.cong;
  const tagsQuery = useTags();
  const tags = tagsQuery.data?.filter((t) => t.category === TagCategory.Address) ?? [];

  const blankTerritoryAddress: TerritoryAddress = {
    id: 0,
    territoryId: props.territory.id,
    sortOrder: defaultSortOrder,
    line1: "",
    dnc: false,
    hideOnMap: false,
    tags: [],
  };
  const [address, setAddress] = useState<TerritoryAddress>(props.address ? props.address : blankTerritoryAddress);

  const [saveStatus, setSaveStatus] = useState(SaveResult.None);
  const queryClient = useQueryClient();
  const saveMutation = useMutation({ mutationFn: (addr: TerritoryAddress) => territoryApi.saveAddress(addr) });
  const deleteMutation = useMutation({ mutationFn: (addressId: number) => territoryApi.deleteAddress(addressId) });
  const tagLinkSaveMutation = useMutation({ mutationFn: (tl: TagLinkUnlink) => tagsAPI.linkUnlink(tl) });
  const [showSpinner, setShowSpinner] = useState(false);
  const [slated, setSlated] = useState(props.address?.tags ?? []);
  const [inFlight, setInFlight] = useState(false);
  const [latitude, setLatitude] = useState(props.address?.location?.y?.toString() ?? "");
  const [longitude, setLongitude] = useState(props.address?.location?.x?.toString() ?? "");
  const [latitudeInvalid, setLatitudeInvalid] = useState(false);
  const [longitudeInvalid, setLongitudeInvalid] = useState(false);
  const [addressBeforeCopy, setAddressBeforeCopy] = useState<TerritoryAddress>();

  const haveAddress = !!address.line1?.trim();

  const handleClose = () => {
    props.setShow(false);
    setInFlight(false);
    setSaveStatus(SaveResult.None);
    setAddress(blankTerritoryAddress);
    setAddressBeforeCopy(undefined);
  };

  const save = async () => {
    try {
      const savedAddress = await saveMutation.mutateAsync(address);
      // savedAddress is returned w/o tags, so we pass them forward
      savedAddress.tags = address.tags || [];

      const updatedCache = await handleSaveLinkUnlink(
        tags,
        slated,
        savedAddress,
        setInFlight,
        queryClient,
        tagLinkSaveMutation,
        setAddress,
      );
      if (!updatedCache) {
        updateTerritoryAddressCache(queryClient, props.territory.id, savedAddress);
      }
      setSaveStatus(SaveResult.Success);
      handleClose();
    } catch (err: any) {
      setSaveStatus(SaveResult.Failure);
      console.error("error saving territory address", err);
      HGBugsnagNotify("saveTerritoryAddress", err);
    } finally {
      setInFlight(false);
    }
  };

  const reverseGeocode = async (lat: number, lng: number) => {
    setShowSpinner(true);
    const location = latLngToMapPoint(lat, lng);
    try {
      const addr = await territoryApi.getAddressFromPoint(lat, lng);
      setAddress({
        ...address,
        line1: addr.line1,
        line2: addr.line2,
        city: addr.city,
        state: addr.state,
        postalcode: addr.postalcode,
        location: location,
      });
    } catch (err: any) {
      switch (getStatusCode(err)) {
        case 404:
        case 429:
          break;
        default:
          console.error("error reverse geocoding", lat, lng);
          HGBugsnagNotify("territoryAddressReverseGeocode", err);
      }
    } finally {
      setShowSpinner(false);
    }
  };

  const deleteAddress = async () => {
    try {
      await deleteMutation.mutateAsync(address.id);
      deleteFromTerritoryAddressCache(queryClient, props.territory.id, address.id);
      handleClose();
    } catch (err: any) {
      console.error("error deleting territory address", err);
      HGBugsnagNotify("deleteTerritoryAddress", err);
    }
  };

  const updateAddress = (key: keyof TerritoryAddress) => {
    return function (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
      setAddress({ ...address, [key]: e.currentTarget.value });
    };
  };

  const mapClick = (p: Position) => {
    if (!haveAddress) {
      const lng = p[0];
      const lat = p[1];
      if (lat && lng) reverseGeocode(lat, lng).then();
    } else {
      setAddress({ ...address, location: mapPointFromPosition(p) });
    }
  };

  //@ts-ignore: we check if the code is working
  const states = States[ctx.globals.cong!.country.code] as CountryState[];
  const geoJson: GeoJsonObject | undefined = props.territory.boundaries
    ? JSON.parse(props.territory.boundaries)
    : undefined;

  const canSave = (!!address.location?.x && !!address.location?.y) || address.line1;
  // if nothing about the address has changed, don't allow saving it
  const copyPreventSave =
    address.location === addressBeforeCopy?.location &&
    address.line1 === addressBeforeCopy?.line1 &&
    address.line2 === addressBeforeCopy?.line2 &&
    address.city === addressBeforeCopy?.city &&
    address.postalcode === addressBeforeCopy?.postalcode;

  type LocaleOption = { value: string; label: string };
  const localeOptions: LocaleOption[] = worldLocales
    .map((l: { code: string; locname: string }) => {
      return { value: l.code, label: localizedLanguageName(i18n.language, l.code) ?? l.locname };
    })
    .sort((a, b) => CollatorSingleton.getInstance().compare(a.label, b.label));
  const congLocale = {
    value: cong!.locale.code,
    label: localizedLanguageName(i18n.language, cong!.locale.code) ?? cong!.locale.name,
  };
  localeOptions.unshift({ value: "", label: "-" }, congLocale);
  const fallbackLocName = worldLocales.find((l) => l.code === address.lang)?.locname ?? address.lang ?? "?";
  const langLabel = address.lang ? (localizedLanguageName(i18n.language, address.lang) ?? fallbackLocName) : undefined;
  const tenYearsAgo = getDayjs(new Date()).subtract(10, "year").format(ISODateFormat);
  const allAddresses = address
    ? [address, ...props.territoryAddresses.filter((a) => a.id !== address.id)]
    : props.territoryAddresses;
  const invalidDetail = longitudeInvalid || latitudeInvalid;

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t("userinfo.address.0")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          <div>
            {!address.location && <strong>{t("schedules.territory.map.place-marker")}</strong>}
            <MapWithGeoJson
              key={`${props.territory.id}_${allAddresses.length}`}
              territory={props.territory}
              settings={props.congSettings}
              geojson={geoJson}
              addresses={allAddresses}
              restrictMarkerToBounds
              onClick={mapClick}
              pathOptions={{ stroke: false }}
              navigateToMap={() => {}}
              setMaplibreMap={() => {
                return;
              }}
            />
          </div>
        }
        {showSpinner && <Spinner animation="border" />}
        <Accordion defaultActiveKey={!address.line1 || props.expandAddress || invalidDetail ? "0" : undefined}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{address.line1 ? addressToString(address) : t("userinfo.address.0")}</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Form.Group>
                  <Form.Label>{t("userinfo.address.0")}</Form.Label>
                  <Form.Control value={address.line1 || ""} onChange={updateAddress("line1")} />
                </Form.Group>
              </Row>
              <Row className="mt-3">
                <Form.Group>
                  <Form.Label>{t("userinfo.address.1")}</Form.Label>
                  <Form.Control value={address.line2 || ""} onChange={updateAddress("line2")} />
                </Form.Group>
              </Row>
              {cong!.country.addrfmt.includes("c") && (
                <Row className="mt-3">
                  <Form.Group>
                    <Form.Label>{t("userinfo.address.city")}</Form.Label>
                    <Form.Control value={address.city || ""} onChange={updateAddress("city")} />
                  </Form.Group>
                </Row>
              )}
              {cong!.country.addrfmt.includes("s") && (
                <Row className="mt-3">
                  <Form.Group controlId="state">
                    <Form.Label>{t("userinfo.address.state")}</Form.Label>
                    {states ? (
                      <Form.Select value={address.state || ""} onChange={updateAddress("state")}>
                        <option value="" />
                        {states.map((state) => {
                          const val = state.abbr ? state.abbr : state.fullname;
                          const disp = state.abbr ? `${state.abbr} - ${state.fullname}` : state.fullname;
                          return (
                            <option key={val} value={val}>
                              {disp}
                            </option>
                          );
                        })}
                      </Form.Select>
                    ) : (
                      <Form.Control value={address.state || ""} onChange={updateAddress("state")} />
                    )}
                  </Form.Group>
                </Row>
              )}
              {cong!.country.addrfmt.includes("p") && (
                <Row className="mt-3">
                  <Form.Group>
                    <Form.Label>{t("userinfo.address.postal-code")}</Form.Label>
                    <Form.Control value={address.postalcode || ""} onChange={updateAddress("postalcode")} />
                  </Form.Group>
                </Row>
              )}
              <Row className="mt-3">
                <Col xs="auto">
                  <Form.Group>
                    <Form.Label>
                      <TbCurrentLocation size={16} className="mx-2" />
                      {t("schedules.territory.address.latitude")}
                    </Form.Label>
                    <Form.Control
                      isInvalid={latitudeInvalid}
                      value={latitude}
                      onChange={(e) => {
                        setLatitude(e.target.value);
                        const location = address.location ?? ({ x: 0, y: 0 } as MapPoint);
                        const number = Number(e.target.value);
                        if (isNaN(number) || number < -90 || number > 90) setLatitudeInvalid(true);
                        else setLatitudeInvalid(false);
                        location.y = number || 0;
                        setAddress({ ...address, location });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs="auto">
                  <Form.Group>
                    <Form.Label>
                      <TbCurrentLocation size={16} className="mx-2" />
                      {t("schedules.territory.address.longitude")}
                    </Form.Label>
                    <Form.Control
                      value={longitude}
                      isInvalid={longitudeInvalid}
                      onChange={(e) => {
                        setLongitude(e.target.value);
                        const location = address.location ?? ({ x: 0, y: 0 } as MapPoint);
                        const number = Number(e.target.value);
                        if (isNaN(number) || number < -180 || number > 180) setLongitudeInvalid(true);
                        else setLongitudeInvalid(false);
                        location.x = number || 0;
                        setAddress({ ...address, location });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Form.Group as={Row} className="mt-2 gx-0">
          <Col xs="auto">
            <Form.Check
              className="fs-4"
              type="switch"
              checked={address.dnc}
              onChange={() => {
                const newAddress = { ...address, dnc: !address.dnc };
                if (address.hideOnMap && newAddress.dnc) newAddress.hideOnMap = false;
                setAddress(newAddress);
              }}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-end">
            <Form.Label>{t("schedules.territory.do-not-call")}</Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-2 gx-0">
          <Col xs="auto">
            <Form.Check
              className="fs-4"
              type="switch"
              checked={address.hideOnMap}
              onChange={() => {
                setAddress({ ...address, hideOnMap: !address.hideOnMap });
              }}
            />
          </Col>
          <Col xs="auto" className="d-flex align-items-end">
            <Form.Label>{t("schedules.territory.address.hide-on-map")}</Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mt-2 gx-0">
          <Col>
            <Form.Control
              size="sm"
              placeholder={t("general.name")}
              value={address.name ?? ""}
              onChange={(e) => {
                setAddress({ ...address, name: e.currentTarget.value });
              }}
            />
          </Col>
          <Col className="px-2">
            <Form.Control
              size="sm"
              placeholder={t("general.phone")}
              value={address.phone ?? ""}
              onChange={(e) => {
                setAddress({ ...address, phone: e.currentTarget.value });
              }}
            />
          </Col>
        </Form.Group>
        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label className="me-3">{t("general.date")}</Form.Label>
              <HourglassDatePicker
                menuType={MenuType.Day}
                date={address.lastWorked || ""}
                onDateChange={(d) => setAddress({ ...address, lastWorked: d })}
                min={tenYearsAgo}
                max={dateToString(new Date())}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Form.Group>
            <Form.Label>{t("congprofile.language")}</Form.Label>
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              closeMenuOnSelect
              isMulti={false}
              isClearable
              value={{ value: address.lang ?? "", label: langLabel ?? "" }}
              options={localeOptions}
              onChange={(e) => {
                setAddress({ ...address, lang: e?.value });
              }}
            />
          </Form.Group>
        </Row>
        <Row className="mt-3">
          {(!props.congSettings.terr_address_hide_type || !!address.locationType) && (
            <Col xs="auto">
              <Form.Group>
                <Form.Label>{t("general.type")}</Form.Label>
                <Form.Select value={address.locationType || ""} onChange={updateAddress("locationType")}>
                  <option value="" />
                  <option value={TerritoryAddressLocationType.Residence}>{t("schedules.territory.residence")}</option>
                  <option value={TerritoryAddressLocationType.Business}>{t("schedules.territory.business")}</option>
                  <option value={TerritoryAddressLocationType.Custom}>{t("general.custom")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          {(props.congSettings.terr_address_h2h || !!address.status) && (
            <Col xs="auto">
              <Form.Group>
                <Form.Label>{t("userinfo.status.0")}</Form.Label>
                <Form.Select value={address.status || ""} onChange={updateAddress("status")}>
                  <option value="" />
                  <option value={TerritoryAddressStatus.ReturnVisit}>{t("schedules.midweek.return-visit")}</option>
                  <option value={TerritoryAddressStatus.NotHome}>{t("schedules.territory.address.not-home")}</option>
                  <option value={TerritoryAddressStatus.NotInterested}>
                    {t("schedules.territory.address.not-interested")}
                  </option>
                  <option value={TerritoryAddressStatus.Worked}>{t("schedules.territory.address.worked")}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col>
            <Form.Group>
              <Form.Label>{t("tags.title")}</Form.Label>
              <TagLinkForm
                tags={tags}
                item={address ?? ({} as Taggable)}
                disabled={inFlight}
                category={TagCategory.Address}
                slated={slated}
                setSlated={setSlated}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              name="notes"
              placeholder={t("userinfo.emergency.notes")}
              rows={3}
              value={address.notes || ""}
              onChange={updateAddress("notes")}
            />
          </Form.Group>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!!address.id && (
          <OverlayTrigger
            trigger="click"
            placement="left"
            rootClose
            overlay={
              <Popover>
                <Popover.Body>
                  <DeleteButton onClick={deleteAddress} text={t("general.delete")} />
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="danger">{t("general.delete")}</Button>
          </OverlayTrigger>
        )}
        {!!props.address && props.address.id > 0 && !addressBeforeCopy && (
          <Button
            variant="secondary"
            onClick={() => {
              setAddressBeforeCopy(address);
              setAddress({
                ...address,
                id: 0,
                hideOnMap: true,
              });
            }}
          >
            {t("general.copy")}
          </Button>
        )}
        {!!props.address && props.address.id > 0 && (
          <Button
            variant="secondary"
            onClick={() => {
              if (props.address) props.setSingleAddressMove(props.address.id);
            }}
          >
            {t("schedules.weekend.move-assignment")}
          </Button>
        )}
        <Button variant="primary" onClick={save} disabled={!canSave || copyPreventSave}>
          {t("general.save")}
        </Button>
        <SaveStatus saveResult={saveStatus} saveKey={props.territory.id} setSaveResult={setSaveStatus} />
        <Button variant="secondary" className="ms-2" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </>
  );
}

function AddMultiTerritoryAddresses(props: { territory: Territory; setShow: (show: boolean) => void }) {
  const { t } = useTranslation();
  const [addrFile, setAddrFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState("");
  const [addresses, setAddresses] = useState<TerritoryAddress[]>([]);
  const [hideOnMap, setHideOnMap] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const tagsQuery = useTags();
  const queryClient = useQueryClient();

  const handleClose = () => {
    props.setShow(false);
    setErrorMessage("");
    setHideOnMap(false);
    setAddresses([]);
    setAddrFile(undefined);
  };

  const onDropFile = useCallback(
    async (acceptedFiles: ConcatArray<File>) => {
      if (acceptedFiles.length < 1) return;
      const file = acceptedFiles[0];
      if (!file) return;
      setAddrFile(file);
      setErrorMessage("");
      const addrTags = new Map<string, Tag>(
        tagsQuery.data
          ?.filter((t) => t.category === TagCategory.Address)
          ?.map((t) => [tagName(t).trim().toLowerCase(), t]),
      );

      try {
        const json = await readXLSX(file);
        if (!json) return;
        const addresses = json
          .filter((rec) => rec.line1)
          .map((rec) => {
            const address: TerritoryAddress = {
              id: 0,
              territoryId: props.territory.id,
              line1: asOptionalString(rec.line1) ?? "",
              line2: asOptionalString(rec.line2) ?? "",
              city: asOptionalString(rec.city) ?? "",
              state: asOptionalString(rec.state) ?? "",
              postalcode: asOptionalString(rec.postalcode) ?? "",
              dnc: typeof rec.dnc === "boolean" ? rec.dnc : false,
              lastWorked: rec.lastWorked instanceof Date ? rec.lastWorked?.toISOString().slice(0, 10) : undefined,
              hideOnMap: typeof rec.hideOnMap === "boolean" ? rec.hideOnMap : hideOnMap && !rec.dnc,
              sortOrder:
                typeof rec.sortOrder === "number" && rec.sortOrder >= 0 && rec.sortOrder <= 9999 ? rec.sortOrder : 9999,
              locationType: rec.locationType ?? "",
              lang: asOptionalString(rec.lang) ?? "",
              status: rec.status ?? "",
              location: isValidLatLng(rec.latitude, rec.longitude)
                ? latLngToMapPoint(rec.latitude, rec.longitude)
                : undefined,
              notes: asOptionalString(rec.notes) ?? "",
              tags: (rec.tags ?? "")
                .split(",")
                .map((t: string) => addrTags.get(t.trim().toLowerCase()))
                .filter((t: Tag | undefined) => !!t) as Tag[],
              name: asOptionalString(rec.name) ?? "",
              phone: asOptionalString(rec.phone) ?? "",
            };
            return address;
          });
        setAddresses(addresses);
      } catch (err: any) {
        console.error("error processing territory addresses file", err);
        setErrorMessage(t("error.invalid-upload-content"));
      }
    },
    [setAddrFile, props.territory.id, hideOnMap, tagsQuery.data, t],
  );

  const doImport = async () => {
    if (!addresses.length) return;

    setShowSpinner(true);
    const input: TerritoryImport = {
      territories: [{ ...props.territory, addresses: addresses }],
      records: [],
    };
    try {
      await territoryApi.importNative(input);
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.TerritoryAddresses, props.territory.id],
      });
      handleClose();
    } catch (err: any) {
      console.error("terr addr CSV import", err);
      HGBugsnagNotify("terrAddrCSV", err);
      setErrorMessage("import.status.failed");
    } finally {
      setShowSpinner(false);
    }
  };

  const { getRootProps: getLocRootProps, getInputProps: getLocInputProps } = useDropzone({ onDrop: onDropFile });
  const allowImport = !showSpinner && addresses.length > 0 && !errorMessage;

  return (
    <>
      <Modal.Body>
        <p>
          <a href={urljoin(import.meta.env.VITE_PUBLIC_URL, "static/territory_addresses.xlsx")}>
            <FileEarmarkSpreadsheet /> {t("schedules.weekend.add-speaker-csv-template")}
          </a>
        </p>
        <Form.Check
          type="switch"
          label={t("schedules.territory.address.hide-on-map")}
          onChange={(e) => setHideOnMap(e.target.checked)}
        />
        <section className="container mt-4 mb-4">
          <div {...getLocRootProps({ className: "dropzone" })}>
            <input {...getLocInputProps()} />
            {!addrFile ? "XSLX" : addrFile.name}
          </div>
        </section>
        {!!errorMessage && <div className="text-danger bold mt-2">{t(errorMessage)}</div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={doImport} disabled={!allowImport}>
          {t("nav.congregation.import")}
        </Button>
        <Button variant="secondary" className="ms-2" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </>
  );
}

export function AddressesTable(props: {
  territory: Territory;
  setTerritory: (t: Territory) => void;
  setShowAddressModal?: (show: boolean) => void;
  setSelectedAddress?: (ta: TerritoryAddress) => void;
  setShowDeleteModal?: (show: boolean) => void;
  onSaveSuccess?: () => void;
  addresses: TerritoryAddress[];
  updateAddresses: (addresses: TerritoryAddress[]) => void;
  noScroll?: boolean;
}) {
  const [filter, setFilter] = useState("");
  const [tagsFilter, setTagsFilter] = useState<(Tag | PseudoTag)[]>([PseudoTag.all]);
  const tagsQuery = useTags();
  const tags = tagsQuery.data?.filter((t) => t.category === TagCategory.Address) ?? [];
  const filteredAddresses = props.addresses
    .filter((a) => filterTags(a, tagsFilter))
    .filter((a) => {
      if (!filter) return true;
      return containsFuzzy(addressToString(a), filter);
    })
    .sort(territoryAddressCompare);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const from = result.source.index;
    const to = result.destination.index;

    const copy = arrayMove([...props.addresses.sort(territoryAddressCompare)], from, to);
    const reordered = copy.map((a, i) => ({ ...a, sortOrder: i }));
    props.updateAddresses(reordered);
  };

  const tableDivClass = (): string => {
    if (props.noScroll) return "";
    return props.addresses.length > 9 ? "table-medium-scrolly-fixed" : "table-medium-scrolly";
  };

  return (
    <div className={tableDivClass()}>
      <Table size="sm">
        <thead>
          <tr>
            <th colSpan={4}>
              <div className="d-flex align-items-center gap-2 px-2 max-w-100">
                <Form.Control
                  size="sm"
                  placeholder={t("search.hint")}
                  value={filter}
                  onChange={(e) => setFilter(e.currentTarget.value)}
                />
                <TagsDDM
                  className="align-self-center mx-3"
                  tags={tags}
                  selected={tagsFilter}
                  setSelected={setTagsFilter}
                  showAllTag
                  showUntaggedTag
                />
                <Button
                  title="CSV"
                  // size="sm"
                  variant="secondary"
                  onClick={() => territoryAddressesXLSX({ ...props.territory, addresses: props.addresses })}
                >
                  <Download />
                </Button>
              </div>
            </th>
          </tr>
          <tr>
            <th className="ps-2">{t("schedules.territory.addresses")}</th>
            <th className="text-muted" colSpan={3}>
              {t("userinfo.emergency.notes")}
            </th>
          </tr>
        </thead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="addresses">
            {(providedDroppable) => (
              <tbody ref={providedDroppable.innerRef} {...providedDroppable.droppableProps}>
                {filteredAddresses.map((ta, index) => (
                  <Draggable key={ta.id} draggableId={`${ta.id}`} index={index}>
                    {(providedDraggable) => (
                      <tr ref={providedDraggable.innerRef} {...providedDraggable.draggableProps}>
                        <td>
                          <div className="d-flex align-items-center gap-1">
                            <span {...providedDraggable.dragHandleProps}>
                              <MdDragHandle size={24} color="gray" />
                            </span>

                            {!ta.hideOnMap && ta.location && getAddressIcon(ta)}
                            {addressToString(ta) || "?"}
                            {!!props.setSelectedAddress && (
                              <Button
                                variant="ghost"
                                size="sm"
                                className="ms-1"
                                onClick={() => {
                                  props.setSelectedAddress?.(ta);
                                  props.setShowAddressModal?.(true);
                                }}
                              >
                                <PencilSquare />
                              </Button>
                            )}
                          </div>
                        </td>
                        <td className="text-muted">{truncate(ta.notes || "", 50)}</td>
                        <td>
                          <TerritoryAddressStatusView address={ta} />
                        </td>
                        <td>
                          <TagList tags={ta.tags} />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {providedDroppable.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </div>
  );
}

function TerritoryAddressStatusView(props: { address: TerritoryAddress }) {
  return (
    <span className="d-flex gap-2 me-1">
      {!!props.address.lang && <BsTranslate title={t("congprofile.language")} />}
      {props.address.locationType === TerritoryAddressLocationType.Business && (
        <Building title={t("schedules.territory.business")} />
      )}
      {props.address.locationType === TerritoryAddressLocationType.Residence && (
        <House title={t("schedules.territory.residence")} />
      )}
      {props.address.locationType === TerritoryAddressLocationType.Custom && <PinMapFill title={t("general.custom")} />}
    </span>
  );
}

function truncate(str: string, length: number) {
  return str.length > length ? str.substring(0, length - 3) + "..." : str;
}

function territoryAddressesXLSX(territory: Territory) {
  if (!territory.addresses || !Array.isArray(territory.addresses) || territory.addresses.length < 1) return;

  const data = territory.addresses.map((ta) => {
    const isValidLocation = !!ta.location?.x && !!ta.location?.y && ta.location.x !== 0 && ta.location.y !== 0;
    return {
      line1: ta.line1,
      line2: ta.line2,
      city: ta.city,
      state: ta.state,
      postalcode: ta.postalcode,
      dnc: ta.dnc,
      lang: ta.lang,
      locationType: ta.locationType,
      status: ta.status,
      latitude: isValidLocation ? ta.location?.y : undefined,
      longitude: isValidLocation ? ta.location?.x : undefined,
      sortOrder: ta.sortOrder,
      hideOnMap: ta.hideOnMap,
      lastWorked: ta.lastWorked ? new Date(ta.lastWorked) : undefined,
      notes: ta.notes,
      tags: ta.tags.map((t) => tagName(t)).join(", "),
      name: ta.name,
      phone: ta.phone,
    };
  });

  const filename = terrLabel(territory) + `_${t("schedules.territory.addresses")}`;
  saveXLSX(data, "addresses", filename);
}

export function getAddressIcon(ta: TerritoryAddress) {
  return <img src={addressIconUrl(ta)} height={15} width={15} className="me-1" alt="" />;
}

export function TerritoryAddressMover(props: {
  show: boolean;
  setShow: (show: boolean) => void;
  territory: Territory;
  addresses: TerritoryAddress[];
  singleAddressId?: number; // when invoked from the address modal, this is the only address to be moved.
}) {
  const { t } = useTranslation();
  const [selectedAddressIds, setSelectedAddressIds] = useState<string[]>([]);
  const [terrFilter, setTerrFilter] = useState("");
  const [selectedTerrOption, setSelectedTerrOption] = useState<TerritoryOption>();
  const territoriesQuery = useTerritories();
  const [showMoveFailure, setShowMoveFailure] = useState(false);
  const queryClient = useQueryClient();
  const handleClose = () => {
    props.setShow(false);
    setSelectedAddressIds([]);
    setTerrFilter("");
    setSelectedTerrOption(undefined);
  };

  const selectOutsideBoundary = () => {
    if (!props.territory.boundaries) return;
    const boundaries = JSON.parse(props.territory.boundaries) as MultiPolygon;
    const outsideAddrIds = new Set<number>(
      props.addresses
        .filter((addr) => {
          if (!addr.location) return false;
          const position = positionFromMapPoint(addr.location);
          if (!boundaries.coordinates.some((b) => b[0] && pointInPolygon(position, b[0]))) return true;
        })
        .map((addr) => addr.id),
    );

    setSelectedAddressIds([...outsideAddrIds].map((aid) => aid.toString()));
    return;
  };

  const possibleTerritories =
    territoriesWithChildren(territoriesQuery.data ?? [])
      ?.filter((t) => t.id !== props.territory.id)
      .filter((t) => {
        const lcFilter = terrFilter.toLocaleLowerCase();
        return t.number.toLocaleLowerCase().includes(lcFilter) || t.locality.toLocaleLowerCase().includes(lcFilter);
      })
      .sort(territoryCompare) ?? [];

  const territoryOptions: TerritoryOption[] = possibleTerritories.map((t) => {
    return { id: t.id, label: terrLabel(t) };
  });

  const move = async () => {
    if (!selectedAddressIds.length || !selectedTerrOption) return;
    setShowMoveFailure(false);
    const moveAddrs: TerritoryMoveAddresses = {
      addressIds: selectedAddressIds.map((idStr) => parseInt(idStr)),
      newTerritoryId: selectedTerrOption.id,
    };
    try {
      await territoryApi.moveAddresses(moveAddrs);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TerritoryAddresses, moveAddrs.newTerritoryId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.TerritoryAddresses, props.territory.id],
        }),
      ]);
      handleClose();
    } catch (err: any) {
      setShowMoveFailure(true);
      console.error("error moving addresses", err);
      HGBugsnagNotify("terrMoveAddrs", err);
    }
  };

  const singleAddress = props.singleAddressId ? props.addresses.find((a) => a.id === props.singleAddressId) : undefined;
  if (singleAddress && selectedAddressIds.length === 0) setSelectedAddressIds([singleAddress.id.toString()]);

  return (
    <Modal show={props.show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("schedules.weekend.move-assignment")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {singleAddress ? (
          addressToString(singleAddress)
        ) : (
          <div>
            {!!props.territory.boundaries && (
              <>
                {t("schedules.territory.address.select-outside-boundary")}
                <Button onClick={selectOutsideBoundary} variant="secondary" className="ms-1 mb-1">
                  <span className="iconZContainer">
                    <PinMap className="background-icon" />
                    <Slash className="foreground-icon" />
                  </span>
                </Button>
              </>
            )}
            <Form.Select
              htmlSize={props.addresses.length > 15 ? 15 : props.addresses.length}
              multiple
              value={selectedAddressIds}
              onChange={(e) => setSelectedAddressIds([...e.currentTarget.selectedOptions].map((i) => i.value))}
            >
              {props.addresses.map((addr) => (
                <option key={addr.id} value={addr.id}>
                  {addressToString(addr)}
                </option>
              ))}
            </Form.Select>
          </div>
        )}
        <p className="mt-4">{t("schedules.territory.address.move")}</p>
        <Typeahead
          className="d-print-none"
          maxHeight="200px"
          onFocus={() => setSelectedTerrOption(undefined)}
          clearButton
          highlightOnlyResult
          selected={selectedTerrOption ? [selectedTerrOption] : []}
          placeholder={t("schedules.territory.territories")}
          id="territory_addr_move_list"
          onChange={(selected) => {
            if (!selected.length) {
              setTerrFilter("");
              setSelectedTerrOption(undefined);
            }
            const sel = selected[0];
            if (!sel || typeof sel !== "object") return;
            const terr = territoryOptions.find((t) => t.id === sel.id);
            if (terr) setSelectedTerrOption(terr);
            setTerrFilter("");
          }}
          onInputChange={(text: string) => setTerrFilter(text)}
          inputProps={{ autoComplete: "off_terr_addr_list", maxLength: 128 }}
          options={territoryOptions}
        />
      </Modal.Body>
      <Modal.Footer>
        {showMoveFailure && <XCircleFill color="red" size={24} className="me-2" />}
        <Button variant="primary" onClick={move} disabled={!selectedTerrOption || !selectedAddressIds.length}>
          {t("schedules.weekend.move-assignment")}
        </Button>
        <Button variant="secondary" className="ms-2" onClick={handleClose}>
          {t("general.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
