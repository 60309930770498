import { Col, Container, ListGroup, Row } from "react-bootstrap";
import HourglassGlobals, { PythonI18nInterpolation } from "../../helpers/globals";
import { dateToString, getDayjs, stringToDate, stringToLongLocaleDate } from "../../helpers/dateHelpers";
import { useMyTerritories } from "../../api/territory";
import { terrLabel } from "../congregation/territory/common";
import { UserTerritory } from "../../types/scheduling/territory";
import { ReportEntry } from "./reportEntry";
import { Link } from "react-router";
import { useTranslation } from "react-i18next";

//this is the component used when someone who has minimal permissions has logged in
export default function RegularPublisherHome() {
  return (
    <Container>
      <MyTerritories />
      <ReportEntry />
    </Container>
  );
}

function MyTerritories() {
  const { t } = useTranslation();
  const territories = useMyTerritories();

  const returnBy = (ut: UserTerritory): string => {
    const start = stringToDate(ut.start!);
    const end = getDayjs(start).add(HourglassGlobals.whoamiSettings?.territory.returnDueDays ?? 120, "day");
    const endStr = stringToLongLocaleDate(dateToString(end.toDate()));
    return `${t("schedules.territory.return-by", { interpolation: PythonI18nInterpolation, date: endStr })}`;
  };

  if (!territories.data || !territories.data.length) return null;

  return (
    <div className="mt-3">
      <h6>{t("schedules.territory.territories")}</h6>
      <Row>
        <Col sm={12} lg={4}>
          <ListGroup>
            {territories.data.map((ut) => (
              <ListGroup.Item as={Link} to={`/territory/${ut.territory.id}`} key={ut.territory.id} action>
                <div className="h5 fw-bolder">{terrLabel(ut.territory)}</div>
                <div className="hg-small-label">{returnBy(ut)}</div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
}
