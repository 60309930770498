import "@maplibre/maplibre-gl-leaflet";
import { createElementObject, createTileLayerComponent, updateGridLayer, type LayerProps } from "@react-leaflet/core";
import L from "leaflet";
import { ResourceType, StyleSpecification } from "maplibre-gl";
import { isMapboxURL, transformMapboxUrl } from "maplibregl-mapbox-request-transformer";
import { mapboxAccessToken } from "./common";

export interface MapLibreTileLayerProps extends L.LeafletMaplibreGLOptions, LayerProps {
  style: StyleSpecification | string;
  attribution: string;
}

export const MapLibreTileLayer = createTileLayerComponent<L.MaplibreGL, MapLibreTileLayerProps>(
  function createTileLayer({ style, attribution, ...options }, context) {
    const transformRequest = (url: string, resourceType?: ResourceType) => {
      if (isMapboxURL(url) && resourceType) {
        return transformMapboxUrl(url, resourceType, mapboxAccessToken);
      }
      return { url };
    };

    const layer = L.maplibreGL({
      style: style,
      // @ts-ignore attribution - look at this later, might not be needed here
      attribution: attribution,
      noWrap: true,
      // turn off style validation to decrease the odds of random problems from provider style changes or maplibre spec changes
      validateStyle: false,
      transformRequest,
      ...options,
    });
    return createElementObject(layer, context);
  },
  function updateTileLayer(layer, props, prevProps) {
    // @ts-ignore this works, some kind of type problem
    updateGridLayer(layer, props, prevProps);

    const { style, attribution } = props;
    if (style && style !== prevProps.style) {
      // @ts-ignore it does exist
      layer.getMaplibreMap()?.setStyle(style);
    }

    if (attribution != null && attribution !== prevProps.attribution) {
      layer.options.attribution = attribution;
    }
  },
);
